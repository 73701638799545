import React, {FC, useState, MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import InfoSolidSvgIcon from 'assets/icons/buttons/InfoSolidSvgIcon';

import {
    getPanelType,
    getPanelDatefilterType,
    getPanelDisplayType,
    TYPE_CASE_STATUS_TIME_ANALYSIS,
} from 'components/Forms/Dashboard/helper';

import PanelTileItem from 'pages/admin/analytics/panels/PanelTileItem';

import useParsedTranslation from 'hooks/useParsedTranslation';

interface InformationAboutPanelType {
    item: PanelItemsTypes;
}

const InformationAboutPanel: FC<InformationAboutPanelType> = ({item}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const {workflowTitle, formTitle, displayType, datefilterType, description, type, isIncludeCurrentStatus} = item;

    return (
        <>
            <IconButton
                title={t('common.buttons.information')}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <InfoSolidSvgIcon />
            </IconButton>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: 500,
                        paddingTop: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                    },
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {workflowTitle && <PanelTileItem label={t('orguser.analytics.panels.workflow')} text={workflowTitle} />}
                <PanelTileItem
                    label={t('orguser.analytics.panels.formTitle')}
                    text={formTitle ? formTitle : t('common.all')}
                />
                <PanelTileItem label={t('orguser.analytics.panels.type')} text={t(getPanelType(type))} />
                {type === TYPE_CASE_STATUS_TIME_ANALYSIS && (
                    <PanelTileItem
                        label={t('orguser.analytics.panels.isIncludeCurrentStatus')}
                        text={t(isIncludeCurrentStatus ? 'common.yes' : 'common.no')}
                    />
                )}
                <PanelTileItem
                    label={t('orguser.analytics.panels.datefilterType')}
                    text={t(getPanelDatefilterType(datefilterType))}
                />
                <PanelTileItem
                    label={t('orguser.analytics.panels.displayType')}
                    text={t(getPanelDisplayType(displayType))}
                />
                <Typography sx={{mt: 1, pr: 1}}>{useParsedTranslation(t, description)}</Typography>
            </Popover>
        </>
    );
};

export default InformationAboutPanel;
