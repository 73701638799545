import React, {FC, ReactNode, useContext, useLayoutEffect, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AdminContextWrapper from 'contexts/admin';
import {RouteContext} from 'contexts/route/context';
import {MediaContext} from 'contexts/media/context';
import PermissionContextWrapper from 'contexts/permission';
import WebsocketProviderWrapper from 'contexts/websocket';

import AgentMenu from 'components/AgentScreenComponents/AgentMenu';
import AgentTopBar from 'components/AgentScreenComponents/AgentTopBar';
import AgentPageActions from 'components/AgentScreenComponents/AgentPageActions';
import LoadingBottomButton from 'components/AgentScreenComponents/_buttons/LoadingBottomButton';
import AgentAddIconButton from 'components/AgentScreenComponents/_buttons/AgentAddIconButton';

import AgentChatWrapper from 'pages/agent/chat/AgentChatWrapper';

import {theme} from 'config/theme';

interface AgentScreenWrapperType {
    setCurrentTab: (value: string) => void;
    toggleAdminMenu: (value: string) => void;
    children: ReactNode;
}

const AgentScreenWrapper: FC<AgentScreenWrapperType> = ({children, setCurrentTab, toggleAdminMenu}) => {
    const [t] = useTranslation();

    const {pageTitle, isOrgUserChatPage, addLink, setIsClientMode} = useContext(RouteContext);
    const {isMobile, isTablet, isMiddle} = useContext(MediaContext);

    const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(isMobile || isTablet || isMiddle);

    const toggleMenu = () => {
        setIsMenuCollapsed(previous => !previous);
    };

    useLayoutEffect(() => {
        setIsMenuCollapsed(isMobile || isTablet || isMiddle);
    }, [isMobile, isTablet, isMiddle]);

    useEffect(() => {
        setIsClientMode(false);
    }, []);

    const isPortable = isMobile || isTablet;

    return (
        <PermissionContextWrapper>
            <AdminContextWrapper>
                <WebsocketProviderWrapper>
                    <LoadingBottomButton />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100vh',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                position: 'fixed',
                                height: '60px',
                                top: 'auto',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                '@media (min-width: 600px)': {
                                    display: 'block',
                                    overflowY: 'scroll',
                                    height: '100vh',
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    top: 0,
                                    bottom: 0,
                                    width: isMenuCollapsed ? '75px' : '240px',
                                },
                                '@media (min-width: 1240px)': {
                                    width: isMenuCollapsed ? '75px' : '240px',
                                },
                                '@media (min-width: 1536px)': {
                                    width: isMenuCollapsed ? '75px' : '290px',
                                },
                                backgroundColor: theme.palette.background.paper,
                            }}
                        >
                            <AgentMenu
                                toggleAdminMenu={toggleAdminMenu}
                                isMenuCollapsed={isMenuCollapsed}
                                toggleMenu={toggleMenu}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: 'fixed',
                                right: 0,
                                top: 0,
                                height: 'calc(100vh - 60px)',
                                width: '100%',
                                overflowY: 'scroll',
                                backgroundColor: theme.palette.warning.contrastText,
                                '@media (min-width: 600px)': {
                                    height: '100vh',
                                    width: isMenuCollapsed ? 'calc(100vw - 75px)' : 'calc(100% - 240px)',
                                },
                                '@media (min-width: 1240px)': {
                                    width: isMenuCollapsed ? 'calc(100vw - 75px)' : 'calc(100% - 240px)',
                                },
                                '@media (min-width: 1536px)': {
                                    width: isMenuCollapsed ? 'calc(100vw - 75px)' : 'calc(100% - 290px)',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    minHeight: isPortable ? 'calc(100vh - 60px)' : '100vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <AgentTopBar setCurrentTab={setCurrentTab} />
                                <Box
                                    sx={{
                                        pl: isMobile ? 2 : 6,
                                        pr: isMobile ? 2 : 4,
                                        pb: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Box>
                                            {pageTitle && (
                                                <Box sx={{display: 'flex', alignItems: 'center', mb: 3.5}}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 40,
                                                            fontWidth: 300,
                                                            lineHeight: '48px',
                                                        }}
                                                    >
                                                        {t(pageTitle)}
                                                    </Typography>
                                                    {addLink && (
                                                        <Link to={addLink} style={{marginLeft: 24}}>
                                                            <AgentAddIconButton />
                                                        </Link>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                        <Box>
                                            <AgentPageActions />
                                        </Box>
                                    </Box>
                                    {isOrgUserChatPage ? <AgentChatWrapper>{children}</AgentChatWrapper> : children}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </WebsocketProviderWrapper>
            </AdminContextWrapper>
        </PermissionContextWrapper>
    );
};

export default AgentScreenWrapper;
