import * as PDFJS from 'pdfjs-dist';

import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';
import {FieldOptionType} from 'appRedux/actions/forms/types';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {base642Buffer} from 'helpers/cryptoApiHelper';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

globalThis.pdfjsWorker = null;
PDFJS.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.min.js`;

const THUMBNAIL_HEIGHT = 150;
const THUMBNAIL_WIDTH = 150;

export const createThumbnail = async (file: File): Promise<string | null> => {
    if (file.type === 'application/pdf') {
        const buffer = await getBuffer(file);
        const pdf = await PDFJS.getDocument(buffer).promise;
        const page = await pdf.getPage(1);
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');

        if (!canvasContext) return null;
        canvas.width = THUMBNAIL_WIDTH;
        canvas.height = THUMBNAIL_HEIGHT;

        const scaleX = canvas.width / page.view[2];
        const scaleY = canvas.height / page.view[3];
        const scale = Math.max(scaleX, scaleY);
        const viewport = page.getViewport({scale});

        await page.render({canvasContext, viewport}).promise;

        console.log(canvas.toDataURL('image/jpeg'));
        return canvas.toDataURL('image/jpeg');
    } else {
        return new Promise(resolve => {
            const img = document.createElement('img');

            getBase64Uri(file)
                .then(dataUri => {
                    img.src = dataUri;

                    img.addEventListener('load', () => {
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        canvas.width = THUMBNAIL_WIDTH;
                        canvas.height = THUMBNAIL_HEIGHT;

                        const scaleX = canvas.width / img.width;
                        const scaleY = canvas.height / img.height;
                        const scale = Math.max(scaleX, scaleY);

                        const imgWidth = img.width * scale;
                        const imgHeight = img.height * scale;

                        context?.drawImage(
                            img,
                            canvas.width / 2 - imgWidth / 2,
                            canvas.height / 2 - imgHeight / 2,
                            imgWidth,
                            imgHeight,
                        );

                        canvas.remove();
                        img.remove();

                        console.log(canvas.toDataURL('image/jpeg'));
                        resolve(canvas.toDataURL('image/jpeg'));
                    });
                })
                .catch(() => resolve(null));
        });
    }
};

export const base64ToFile = (base64String: string) => {
    const arrayBuffer = base642Buffer(base64String.split(',')[1]);
    const blob = new Blob([arrayBuffer], {type: 'image/jpeg'});
    return blob;
};

export const getBase64Uri = (file: File): Promise<string> => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = function () {
            typeof reader.result === 'string' ? resolve(reader.result) : reject();
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};

const getBuffer = (file: File): Promise<ArrayBuffer> => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = function () {
            reader.result && typeof reader.result !== 'string' ? resolve(reader.result) : reject();
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsArrayBuffer(file);
    });
};

export const isRequesterCaseOptionPresented = (
    optionId: number,
    requesterCaseOptions: RequesterCaseOptionTypes[],
): boolean => {
    return requesterCaseOptions.some(item => item.optionId === optionId);
};

export const getInitialSelectedOption = (
    fieldId: number,
    requesterCaseOptions: RequesterCaseOptionTypes[],
): RequesterCaseOptionTypes | undefined => {
    return requesterCaseOptions.find(item => item.fieldId === fieldId);
};

export const getTextFieldLabel = (
    label: string,
    translatedLabel: string,
    previewMode?: boolean,
    isCustomOption?: boolean,
): string => {
    if (isCustomOption) return '';
    return previewMode ? label : translatedLabel;
};

export const getOptionsIdsArray = (options: FieldOptionType[]): number[] => {
    const optionsIds: number[] = [];
    options.forEach(item => {
        optionsIds.push(item.id);
    });
    return optionsIds;
};

export const isSelectedOptionCustom = (options: FieldOptionType[], optionId): boolean => {
    return options.some(option => option.id === optionId && option.isCustom);
};

export const getDropdownSelectedInitialValue = (
    options: FieldOptionType[],
    customSelectedOptionId: number | null,
    previewModeSelectedOption: number | null,
    previewMode?: boolean,
    initialSelectedOption?: RequesterCaseOptionTypes,
): string => {
    if (previewMode) {
        return String(previewModeSelectedOption);
    }
    if (customSelectedOptionId) {
        const selectedOption = options.find(option => option.id === customSelectedOptionId);
        return selectedOption ? String(selectedOption.id) : '';
    }
    if (initialSelectedOption) {
        return String(initialSelectedOption.optionId);
    }
    return '';
};

export const getSelectedCustomCheckbox = (
    options: FieldOptionType[],
    requesterCaseOptions: RequesterCaseOptionTypes[],
): FieldOptionType | null => {
    for (let i = 0, n = options.length; i < n; i++) {
        const option = options[i];
        if (isRequesterCaseOptionPresented(option.id, requesterCaseOptions) && option.isCustom) {
            return option;
        }
    }
    return null;
};

export const getCurrentFieldValue = (
    value: string,
    optionsIds?: number[],
    isCustomOption?: boolean,
    isCustomCheckboxField?: boolean,
): string => {
    if (isCustomOption && optionsIds && isCustomCheckboxField) {
        const valueArray = value.split(OPTIONS_SEPARATOR);
        const results: string[] = [];
        valueArray.forEach(item => {
            if (!optionsIds.includes(Number(item))) {
                results.push(item);
            }
        });
        return results.join(OPTIONS_SEPARATOR);
    }
    if (isCustomOption && optionsIds && optionsIds.includes(Number(value))) return '';
    return value;
};

export const getInitialSelectedOptions = (
    options: FieldOptionType[],
    requesterCaseOptions: RequesterCaseOptionTypes[],
    initialValue: string,
): string[] => {
    const initialValuesArray = initialValue ? initialValue.split(OPTIONS_SEPARATOR) : [];
    const customAnswer: string | null = getCustomAnswerFromInitialValue(options, initialValuesArray);
    const results: string[] = [];
    requesterCaseOptions.forEach(caseSelectedOptions => {
        const option = options.find(option => option.id === caseSelectedOptions.optionId);
        if (option && option.isCustom && customAnswer) {
            results.push(customAnswer);
        } else if (option) {
            results.push(String(caseSelectedOptions.optionId));
        }
    });
    return results;
};

export const getCustomAnswerFromInitialValue = (
    options: FieldOptionType[],
    initialValuesArray: string[],
): string | null => {
    const optionsIds: number[] = getOptionsIdsArray(options);
    const results: string[] = [];
    initialValuesArray.forEach(initialValue => {
        if (!optionsIds.includes(Number(initialValue))) {
            results.push(initialValue);
        }
    });
    return results.length > 0 ? results.join(OPTIONS_SEPARATOR) : null;
};
