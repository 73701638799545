import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import TextWrappedLabel from 'components/TextWrappedLabel';

import {isSelectionFields} from 'pages/admin/updateForm/partials/FormStructure/helper';
import SelectedFieldPrintContent from 'pages/agent/print/partials/SelectedFieldPrintContent';
import {getFieldTitleLabel} from 'pages/agent/requesterPage/wrappers/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface FieldPrintContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    field: FormFieldTypes;
    result: RequesterCaseItemType;
}

const FieldPrintContent: FC<FieldPrintContentType> = ({formId, pageId, sectionId, field, result}) => {
    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {label, id, type} = field;

    const keyword = getFieldKeyword(formId, id, 'label');

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const fieldKeyword = `field-${id}`;

    const value = get(result, [pageKeyword, sectionKeyword, fieldKeyword], EMPTY_DATA);

    const fieldTitle = getFormTranslatedLabel(translations, keyword, label);

    return (
        <Grid sx={{mb: 1}} container>
            <Grid item sm={3}>
                <Typography sx={{fontWeight: 600}}>{`${getFieldTitleLabel(field, fieldTitle)}:`}</Typography>
            </Grid>
            <Grid item sm={9}>
                {isSelectionFields(type) ? (
                    <SelectedFieldPrintContent formId={formId} field={field} clientText={value} />
                ) : (
                    <TextWrappedLabel text={value} />
                )}
            </Grid>
        </Grid>
    );
};

export default FieldPrintContent;
