import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {OrganizationTimeBucketsTypes} from 'appRedux/actions/organization/types';
import {DELETE_ORGANIZATION_TIME_BUCKET} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeleteTimeBucketButtonType {
    item: OrganizationTimeBucketsTypes;
}

const DeleteTimeBucketButton: FC<DeleteTimeBucketButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid} = item;

    const removeTimeBucket = useCallback(
        data => dispatch({type: DELETE_ORGANIZATION_TIME_BUCKET.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeTimeBucket({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <>
            <IconButton title={t('common.buttons.delete')} onClick={toggleIsOpened}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeleteTimeBucketButton;
