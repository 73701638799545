import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE, GET_REQUESTER_CASE_POPUPS} from 'appRedux/actions/requestCase';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';

import CaseKeyContextWrapper from 'contexts/caseKey';

import GeneralErrorMessage from 'components/GeneralErrorMessage/GeneralErrorMessage';

import PrintViewContent from 'pages/agent/print/PrintViewContent';

const PrintView: FC = () => {
    const dispatch = useDispatch();
    const {formId, caseId} = useParams();

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCasePopups = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_POPUPS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        errors: {generalError},
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getFormByCaseInformation({id: caseId});
        getRequestCaseInformation({id: caseId});
        getRequesterCasePopups({id: caseId});
    }, [caseId]);

    if (generalError) {
        return <GeneralErrorMessage />;
    }

    if (isEncryptInfo)
        return (
            <CaseKeyContextWrapper caseId={Number(caseId)}>
                <PrintViewContent />
            </CaseKeyContextWrapper>
        );

    return <PrintViewContent />;
};

export default PrintView;
