import React, {FC, useState, useMemo, useContext, useEffect, useCallback} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';

import {GET_UNAVAILABLE_CALENDAR_RANGES} from 'appRedux/actions/requestCase';
import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {AvailableBookframeItemTypes, ResourceFieldCalendarClientItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';
import {AlertContext} from 'contexts/alert/context';

import {
    getResourceFieldWeekdayLabel,
    CALENDAR_EXCEPTION_BLOCKED_DATE,
    CALENDAR_EXCEPTION_CHANGED_TIMES,
} from 'components/Forms/ResourceField/helper';

import {
    getDateSelectionPossibleWeekdays,
    getAvailableBookframes,
    checkIsWeekdayAvailable,
    isBlockedDate,
    isBeforeAndNotToday,
    checkIsPreviousButtonDisabled,
} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';
import CalendarBookframeItem from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarBookframeItem';
import CalendarWeekdayItem from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarWeekdayItem';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {STATUS_OPENING, DATE_FORMAT_FILTER} from 'config/index';

interface CalendarStepThreeProps {
    isDisabled: boolean;
    currentCalendar: RequesterCaseHasCalendarTypes;
    setCurrentTab: (value: number) => void;
    calendarInfo: ResourceFieldCalendarClientItemTypes;
}

const CalendarStepThree: FC<CalendarStepThreeProps> = ({currentCalendar, setCurrentTab, calendarInfo, isDisabled}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [currentWeekOffset, setCurrentWeekOffset] = useState(0);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const {isMobile, isTablet} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);

    const possibleWeekdays = getDateSelectionPossibleWeekdays(calendarInfo.bookframes);
    const selectedSlot = calendarInfo.slots.find(slot => slot.id === currentCalendar.resourceFieldCalendarSlot);

    const getUnavailableCalendarRanges = useCallback(
        data => dispatch({type: GET_UNAVAILABLE_CALENDAR_RANGES.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const blockedDatesExceptions = calendarInfo.exceptions.filter(
        item => item.type === CALENDAR_EXCEPTION_BLOCKED_DATE,
    );

    const changedDatesExceptions = calendarInfo.exceptions.filter(
        item => item.type === CALENDAR_EXCEPTION_CHANGED_TIMES,
    );

    const weekDates = useMemo(() => {
        const today = new Date();
        const currentWeekDate = new Date(today.setDate(today.getDate() + currentWeekOffset * 7));
        const weekDates: Date[] = [];
        const firstDayOfWeek = new Intl.Locale(navigator.language)?.weekInfo?.firstDay || 1; // Default to Monday if not available

        const startOfWeek = new Date(currentWeekDate);
        const currentDay = currentWeekDate.getDay();
        const diff = currentDay >= firstDayOfWeek ? currentDay - firstDayOfWeek : 7 - (firstDayOfWeek - currentDay);
        startOfWeek.setDate(currentWeekDate.getDate() - diff);

        for (let i = 0; i < 7; i++) {
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + i);
            weekDates.push(date);
        }

        return weekDates;
    }, [currentWeekOffset]);

    const availableWeekdays = useMemo(
        () =>
            weekDates.filter(date => {
                return checkIsWeekdayAvailable(possibleWeekdays, changedDatesExceptions, date);
            }),
        [weekDates, possibleWeekdays],
    );

    if (!selectedSlot || possibleWeekdays.length === 0) {
        return (
            <Box>
                <Typography>{t('requester.casePage.calendarSlotRegistrationImpossible')}</Typography>
            </Box>
        );
    }

    const isPreviousButtonDisabled = checkIsPreviousButtonDisabled(weekDates);

    const goToPreviousWeek = () => {
        if (!isPreviousButtonDisabled) {
            setCurrentWeekOffset(prev => prev - 1);
        }
    };

    const goToNextWeek = () => {
        setCurrentWeekOffset(prev => prev + 1);
    };

    const getBookframesForDay = (selectedDate: Date) => {
        return getAvailableBookframes(
            calendarInfo.bookframes,
            selectedDate,
            selectedSlot.duration,
            changedDatesExceptions,
        );
    };

    useEffect(() => {
        if (!selectedDate && currentCalendar.calendarDate) {
            const calendarDate = new Date(currentCalendar.calendarDate);
            setSelectedDate(calendarDate);

            // Calculate week offset
            const today = new Date();
            const weekOffset = Math.round((calendarDate.getTime() - today.getTime()) / (7 * 24 * 60 * 60 * 1000));
            setCurrentWeekOffset(weekOffset);
        }
    }, [currentCalendar, calendarInfo, selectedSlot, selectedDate]);

    useEffect(() => {
        if (weekDates.length === 7 && currentCalendar && currentCase && currentCase.status === STATUS_OPENING) {
            getUnavailableCalendarRanges({
                uuid: currentCalendar.uuid,
                startDate: weekDates[0],
                endDate: weekDates[6],
                showAlert,
            });
        }
    }, [currentWeekOffset]);

    console.log('availableWeekdays', availableWeekdays);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pb: 1,
                    mb: 1,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <IconButton onClick={goToPreviousWeek} disabled={isPreviousButtonDisabled}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h5" sx={{fontWeight: 700, mt: 0.5, fontSize: isMobile ? '1rem' : undefined}}>
                    {`${convertDateToCurrentLocale(weekDates[0], true)} - ${convertDateToCurrentLocale(
                        weekDates[6],
                        true,
                    )}`}
                </Typography>
                <IconButton onClick={goToNextWeek}>
                    <ChevronRightIcon />
                </IconButton>
            </Box>

            {isMobile || isTablet ? (
                // Mobile Layout:
                <Box sx={{display: 'flex', width: '100%'}}>
                    <List
                        sx={{
                            width: '50%',
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            pr: 1,
                            maxHeight: 400,
                            overflowY: 'auto',
                        }}
                    >
                        {availableWeekdays.map(date => {
                            const weekday = date.getDay();

                            return (
                                <CalendarWeekdayItem
                                    key={`weekday-${weekday}`}
                                    date={date}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    blockedDatesExceptions={blockedDatesExceptions}
                                />
                            );
                        })}
                    </List>
                    <Box sx={{width: '50%', pl: 2, maxHeight: 400, overflowY: 'auto'}}>
                        {selectedDate && (
                            <Box>
                                {getBookframesForDay(selectedDate).map((bookframe: AvailableBookframeItemTypes) => (
                                    <CalendarBookframeItem
                                        key={`calendar-bookframe-${bookframe.slotStartTime}`}
                                        currentCalendar={{
                                            ...currentCalendar,
                                        }}
                                        itemDate={selectedDate.toISOString()}
                                        blockedDatesExceptions={blockedDatesExceptions}
                                        bookframe={bookframe}
                                        setCurrentTab={setCurrentTab}
                                        isDisabled={isDisabled}
                                    />
                                ))}
                            </Box>
                        )}
                        {!selectedDate && (
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                <Typography color="text.secondary">
                                    {t('requester.casePage.calendarSelectDay')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                // Desktop Layout:
                <Box sx={{display: 'flex', width: '100%'}}>
                    {availableWeekdays.map((date, index) => {
                        const weekday = date.getDay();
                        const isWeekdayAvailable = checkIsWeekdayAvailable(
                            possibleWeekdays,
                            changedDatesExceptions,
                            date,
                        );
                        const currentDate = moment(date).format(DATE_FORMAT_FILTER);
                        return (
                            <Box
                                key={`weekday-${weekday}`}
                                sx={{
                                    flex: 1,
                                    p: 1,
                                    borderRight:
                                        index < availableWeekdays.length - 1 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
                                    opacity:
                                        isBeforeAndNotToday(currentDate) ||
                                        isBlockedDate(blockedDatesExceptions, currentDate)
                                            ? 0.5
                                            : 1,
                                }}
                            >
                                <Typography
                                    align="center"
                                    sx={{
                                        fontWeight: 'bold',
                                        mb: 1,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                                        pb: 1,
                                    }}
                                >
                                    {t(getResourceFieldWeekdayLabel(weekday))}
                                    <br />
                                    {convertDateToCurrentLocale(date, true)}
                                </Typography>

                                {isWeekdayAvailable && (
                                    <Box sx={{maxHeight: 400, overflowY: 'auto'}}>
                                        {getBookframesForDay(date).map((bookframe: AvailableBookframeItemTypes) => (
                                            <CalendarBookframeItem
                                                key={`calendar-bookframe-${bookframe.uuid}-${weekday}`}
                                                currentCalendar={{
                                                    ...currentCalendar,
                                                }}
                                                bookframe={bookframe}
                                                blockedDatesExceptions={blockedDatesExceptions}
                                                setCurrentTab={setCurrentTab}
                                                itemDate={date.toISOString()}
                                                isDisabled={isDisabled}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default CalendarStepThree;
