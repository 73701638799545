import React, {FC, useEffect, useState, ReactNode, useCallback, useContext} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {GET_NEW_CHAT_MESSAGES, CHAT_AGENT_CONTACTS} from 'appRedux/actions/requestChat';
import {GET_ALL_REQUESTER_CASES, GET_REQUESTER_CASE, GET_REQUESTER_CASE_CLIENT} from 'appRedux/actions/requestCase';
import {GET_NOTIFICATIONS_LIST} from 'appRedux/actions/notifications';
import {ChatMessageTypes} from 'appRedux/actions/requestChat/types';
import {RootReducer} from 'appRedux/reducers';

import {WebsocketContext, WebsocketContextType, WEBSOCKET_PING_INTERVAL} from 'contexts/websocket/context';
import ShowLoginToYourAccountPopup from 'contexts/websocket/partials/ShowLoginToYourAccountPopup';
import {RouteContext} from 'contexts/route/context';

import {getNewestMessageId} from 'helpers/chatHelper';

import {LOCAL_STORAGE_TOKEN} from 'services/localStorage';

import {
    WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE,
    WEBSOCKET_NEW_NOTIFICATION,
    WEBSOCKET_NOTIFICATION_ANOTHER_LOGIN,
} from 'config/index';

interface ContextType {
    children: ReactNode;
}

const WebsocketProviderWrapper: FC<ContextType> = ({children}) => {
    const dispatch = useDispatch();
    const {requestCase, caseId} = useParams();

    const {isAdminPage, isAgentPage, isClientMode, isCurrentUserRequesterCasesListPage, isRequesterFormPage} =
        useContext(RouteContext);

    const [notReadMessagesCounter, setNotReadMessagesCounter] = useState<number>(0);
    const [isShowScrollBottomButton, setIsShowScrollBottomButton] = useState<boolean>(false);
    const [isShowLoginToYourAccountPopup, setIsShowLoginToYourAccountPopup] = useState<boolean>(false);

    const toggleShowLoginToYourAccountPopup = () => {
        setIsShowLoginToYourAccountPopup(previous => !previous);
    };

    const updateMessagesCounter = () => {
        setNotReadMessagesCounter(previous => previous + 1);
    };

    const getChatContacts = useCallback(() => dispatch({type: CHAT_AGENT_CONTACTS.REQUEST_BACKGROUND}), [dispatch]);

    const getNewChatMessages = useCallback(
        data => dispatch({type: GET_NEW_CHAT_MESSAGES.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getAllRequesterCases = useCallback(
        data => dispatch({type: GET_ALL_REQUESTER_CASES.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformationForClient = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_CLIENT.REQUEST_BACKGROUND, payload: data}),
        [dispatch],
    );

    const getNotifications = useCallback(() => dispatch({type: GET_NOTIFICATIONS_LIST.REQUEST}), [dispatch]);

    const {
        profile,
        requestChat: {messages, newestMessageId},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}`);

        socket.onopen = () => {
            console.log('WebSocket opened');
            const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
            if (token) {
                socket.send(token);
                console.log('WebSocket token is sent');
                setInterval(function () {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({type: 'ping'}));
                    }
                }, WEBSOCKET_PING_INTERVAL);
            }
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
        };

        socket.onmessage = event => {
            const message = event.data;
            console.log('WebSocket message received:', message);

            if (message.includes(WEBSOCKET_NOTIFICATION_ANOTHER_LOGIN)) {
                const timer = setTimeout(() => {
                    getNotifications();
                    setIsShowLoginToYourAccountPopup(true);
                }, 2000);
                return () => clearTimeout(timer);
            } else if (
                message.includes('type') &&
                message.includes('caseId') &&
                message.includes(WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE)
            ) {
                newChatMessageHandling(message);
            } else if (message.includes('type') && message.includes(WEBSOCKET_NEW_NOTIFICATION)) {
                const timer = setTimeout(() => {
                    getNotifications();
                }, 2000);
                return () => clearTimeout(timer);
            }
        };

        socket.onerror = error => {
            console.log('WebSocket error:', error);
        };

        return () => {
            socket.close();
        };
    }, [requestCase, caseId, messages, newestMessageId]);

    const newChatMessageHandling = (message: string) => {
        const type = get(JSON.parse(message), 'type', null);
        const senderCaseId = get(JSON.parse(message), 'caseId', null);
        const isCurrentCase =
            senderCaseId && (Number(senderCaseId) === Number(requestCase) || Number(senderCaseId) === Number(caseId));

        if (type === WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE) {
            if (isAdminPage || isAgentPage) {
                updateChatContacts();
            }
            if (isCurrentCase) {
                updateChatMessages(messages, newestMessageId);
            }
            if (isClientMode || isCurrentUserRequesterCasesListPage) {
                const userId = get(profile, ['profile', 'id'], null);
                getAllRequesterCases({
                    id: userId,
                });
            }
            if (isRequesterFormPage && isCurrentCase) {
                if (isClientMode) {
                    getRequestCaseInformationForClient({id: caseId ? caseId : requestCase});
                } else {
                    getRequestCaseInformation({id: caseId ? caseId : requestCase});
                }
            }
        }
    };

    const updateChatContacts = () => {
        getChatContacts();
    };

    const updateChatMessages = (messages: ChatMessageTypes, newestMessageId: string) => {
        const lastMessageUuid: string = getNewestMessageId(messages, newestMessageId);
        const currentCaseId = requestCase ? Number(requestCase) : Number(caseId);
        if (lastMessageUuid && currentCaseId) {
            getNewChatMessages({
                id: currentCaseId,
                lastMessageUuid,
                callback: () => {
                    setIsShowScrollBottomButton(true);
                    updateMessagesCounter();
                },
            });
        }
    };

    const context: WebsocketContextType = {
        isShowScrollBottomButton,
        setIsShowScrollBottomButton,
        notReadMessagesCounter,
        setNotReadMessagesCounter,
    };

    return (
        <WebsocketContext.Provider value={context}>
            {children}
            <ShowLoginToYourAccountPopup
                isShowLoginToYourAccountPopup={isShowLoginToYourAccountPopup}
                setIsShowLoginToYourAccountPopup={setIsShowLoginToYourAccountPopup}
                toggleShowLoginToYourAccountPopup={toggleShowLoginToYourAccountPopup}
            />
        </WebsocketContext.Provider>
    );
};

export default WebsocketProviderWrapper;
