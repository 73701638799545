import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import ClientFormContextWrapper from 'contexts/clientForm';

import ClientForm from 'pages/client/form/ClientForm';

import {DEFAULT_PAGE} from 'config/index';

const TicketCreationPage: FC = () => {
    const {requestCase} = useParams();
    const dispatch = useDispatch();

    const {
        backTab,
        setBackLink,
        setPageTitle,
        setFormPage,
        setFormSection,
        setTopBarTitle,
        setBackLinkLabel,
        setTopBarSubtitle,
    } = useContext(RouteContext);

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getRequestCaseInformation({id: requestCase});
    }, []);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setTopBarSubtitle('');
        setBackLinkLabel('');
        setFormPage(DEFAULT_PAGE);
        setFormSection(null);
    }, [currentCase, backTab]);

    return (
        <ClientFormContextWrapper>
            <ClientForm />
        </ClientFormContextWrapper>
    );
};

export default TicketCreationPage;
