import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {
    CONTENT_FIELD,
    CONTENT_POPUP,
    getSectionContent,
    SectionContentType,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FieldPrintContent from 'pages/agent/print/partials/FieldPrintContent';
import PopupPrintContent from 'pages/agent/print/partials/PopupPrintContent';

import {getSectionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

interface SectionPrintContentType {
    pageId: number;
    section: FormSectionTypes;
    expandAll: boolean;
    result: RequesterCaseItemType;
}

const SectionPrintContent: FC<SectionPrintContentType> = ({pageId, section, expandAll, result}) => {
    const {formId} = useParams();

    const [expanded, setExpanded] = useState<boolean>(false);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleExpanded = () => {
        setExpanded(previous => !previous);
    };

    const {title, id, fields, popups} = section;

    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll]);

    const sectionContent: SectionContentType[] = getSectionContent(fields, popups);

    const keyword = getSectionKeyword(Number(formId), id, 'title');

    return (
        <Accordion
            sx={{backgroundColor: theme.palette.background.default}}
            onChange={toggleExpanded}
            expanded={expanded}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{fontWeight: 600}}>{getFormTranslatedLabel(translations, keyword, title)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {sectionContent.map((item: SectionContentType, index: number) => {
                    if (item.type === CONTENT_FIELD) {
                        return (
                            <FieldPrintContent
                                key={`field-${index}`}
                                formId={Number(formId)}
                                pageId={pageId}
                                sectionId={id}
                                field={fields[item.index]}
                                result={result}
                            />
                        );
                    } else if (item.type === CONTENT_POPUP) {
                        return (
                            <PopupPrintContent
                                key={`popup-${index}`}
                                formId={Number(formId)}
                                pageId={pageId}
                                sectionId={id}
                                popup={popups[item.index]}
                            />
                        );
                    }
                    return null;
                })}
            </AccordionDetails>
        </Accordion>
    );
};

export default SectionPrintContent;
