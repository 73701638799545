import React, {FC, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {CaseKeyContext} from 'contexts/caseKey/context';

import {FORM_FIELD_DATE_RANGE} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {isSelectionFields, OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FieldTranslation from 'pages/agent/requesterPage/translation/FieldTranslation';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';
import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

interface PopupInformationItemRowType {
    keyword: string;
    value: string;
    field: FormFieldTypes;
    sectionId: number;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
    popupItem: number;
}

const PopupInformationItemRow: FC<PopupInformationItemRowType> = ({
    keyword,
    value,
    field,
    sectionId,
    clientTranslations,
    setClientTranslations,
    showTranslation,
    editTranslation,
    popupItem,
}) => {
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [text, setText] = useState<string>('-');

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const timer = setTimeout(async () => {
            let init = value;
            if (isEncryptInfo && unwrappedCaseKey) {
                init = await decryptStringWithKey(value, unwrappedCaseKey);
            }
            setText(init);
        }, 200);
        return () => clearTimeout(timer);
    }, [value]);

    let formattedText = text.replace(/<[^>]*>/g, '');

    if (field.type === FORM_FIELD_DATE_RANGE) {
        formattedText = formattedText.replace('&', ' - ');
    }

    return (
        <Box>
            <Box sx={{mb: 2}}>
                <Typography variant="body1" sx={{fontWeight: 600, mb: 1}}>
                    {`${getFormTranslatedLabel(translations, keyword, field.label)}: `}
                </Typography>
                <Typography variant="body1">
                    {isSelectionFields(field.type) ? formattedText.replaceAll(OPTIONS_SEPARATOR, ', ') : formattedText}
                </Typography>
            </Box>
            {setClientTranslations && (
                <FieldTranslation
                    field={field}
                    currentValue={text}
                    sectionId={sectionId}
                    clientTranslations={clientTranslations}
                    setClientTranslations={setClientTranslations}
                    editTranslation={editTranslation}
                    showTranslation={showTranslation}
                    popupItem={popupItem}
                />
            )}
        </Box>
    );
};

export default PopupInformationItemRow;
