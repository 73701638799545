import React, {FC, useRef, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {Caret} from 'textarea-caret-ts';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {CommentFormType} from 'appRedux/actions/comments/types';
import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import CommentTestField from 'components/Forms/CommentForm/CommentTextField';
import {commentValidation} from 'components/Forms/CommentForm/validation';
import {getFilteredAgents} from 'components/Forms/CommentForm/helper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import MentionsList from 'components/Forms/CommentForm/MentionsList';

import {MENTION_MARKER} from 'pages/agent/requesterPage/common/helper';

interface ComponentType {
    initialValues: CommentFormType;
    handleSubmit: (values: CommentFormType) => void;
    closeForm: () => void;
    handleStackSubmit?: (values: CommentFormType) => void;
    isModal?: boolean;
    inStack?: boolean;
}

const CommentForm: FC<ComponentType> = ({
    initialValues,
    closeForm,
    handleSubmit,
    handleStackSubmit,
    isModal,
    inStack,
}) => {
    const [t] = useTranslation();
    const validationSchema = () => commentValidation(t);

    const [value, setValue] = useState<string>(initialValues.text ? initialValues.text : '');
    const [showMentions, setShowMentions] = useState<boolean>(false);
    const [searchAgent, setSearchAgent] = useState<string | null>(null);
    const [topPosition, setTopPosition] = useState<number | null>(null);
    const [leftPosition, setLeftPosition] = useState<number | null>(null);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);
    const [currentMenuItem, setCurrentMenuItem] = useState<number>(0);

    const commentFieldInput = useRef<HTMLInputElement | null>(null);

    const {
        crypto: {agentsWithCaseAccess},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const closeMentions = () => {
        setShowMentions(false);
        setTopPosition(null);
        setLeftPosition(null);
        setSearchAgent(null);
        setCurrentMenuItem(0);
    };

    const selectAgent = (id: number) => {
        if (cursorPosition) {
            let newValue = value.slice(0, cursorPosition) + `agent_${id} ` + value.slice(cursorPosition);
            if (searchAgent) {
                newValue = newValue
                    .replace(`${MENTION_MARKER}${searchAgent}`, MENTION_MARKER)
                    .replaceAll(MENTION_MARKER, ` ${MENTION_MARKER}`);
                newValue = newValue.trim().split(/\s+/).join(' ');
            }
            setValue(newValue);
            closeMentions();
        }
        if (commentFieldInput && commentFieldInput.current) {
            commentFieldInput.current.focus();
        }
    };

    const onFocusOut = () => {
        if (!isModal && commentFieldInput && commentFieldInput.current && !commentFieldInput.current.value) {
            closeForm();
        }
    };

    const filteredAgents = getFilteredAgents(agentsWithCaseAccess, searchAgent);

    return (
        <ClickAwayListener onClickAway={onFocusOut}>
            <Box sx={{width: '100%'}}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values: CommentFormType) => {
                        if (handleStackSubmit) {
                            handleStackSubmit({
                                ...values,
                                text: value,
                            });
                        } else {
                            handleSubmit({
                                ...values,
                                text: value,
                            });
                        }
                    }}
                >
                    {(formik: FormikProps<CommentFormType>) => {
                        return (
                            <Form>
                                <Box sx={{position: 'relative'}}>
                                    <Field
                                        inputRef={commentFieldInput}
                                        required
                                        fullWidth
                                        name="text"
                                        label={t('orguser.requesterCase.comments.comment')}
                                        placeholder={t('orguser.requesterCase.comments.addComment')}
                                        value={value}
                                        onChange={e => {
                                            const value = e.target.value;
                                            const selectionStart = e.target.selectionStart;
                                            setValue(value);
                                            setCursorPosition(selectionStart);
                                            formik.setFieldValue('text', value);
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === MENTION_MARKER) {
                                                if (commentFieldInput && commentFieldInput.current) {
                                                    const caretPosition = Caret.getRelativePosition(
                                                        commentFieldInput.current,
                                                    );
                                                    setTopPosition(caretPosition.top);
                                                    setLeftPosition(caretPosition.left);
                                                    setShowMentions(true);
                                                }
                                            }
                                            if (e.key === 'Enter' && showMentions) {
                                                e.preventDefault();
                                                const selectedValue = get(filteredAgents, currentMenuItem, null);
                                                if (selectedValue) {
                                                    selectAgent(selectedValue.id);
                                                }
                                            } else if (e.key === 'ArrowDown') {
                                                if (currentMenuItem + 1 < filteredAgents.length) {
                                                    setCurrentMenuItem(currentMenuItem + 1);
                                                } else {
                                                    setCurrentMenuItem(0);
                                                }
                                            } else if (e.key === 'ArrowUp') {
                                                if (currentMenuItem - 1 < 0) {
                                                    setCurrentMenuItem(filteredAgents.length - 1);
                                                } else {
                                                    setCurrentMenuItem(currentMenuItem - 1);
                                                }
                                            } else if (showMentions && e.key !== 'Backspace' && e.key !== 'Shift') {
                                                const initialValue = searchAgent ? searchAgent : '';
                                                setSearchAgent(initialValue + e.key);
                                            } else if (showMentions && e.key === 'Backspace') {
                                                const initialValue = searchAgent ? searchAgent : '';
                                                setSearchAgent(initialValue.slice(0, -1));
                                            }
                                        }}
                                        component={CommentTestField}
                                        multiline
                                        rows={4}
                                    />
                                    {showMentions && filteredAgents.length > 0 && (
                                        <MentionsList
                                            selectAgent={selectAgent}
                                            currentMenuItem={currentMenuItem}
                                            commentFieldInput={commentFieldInput}
                                            topPosition={topPosition}
                                            leftPosition={leftPosition}
                                            closeMentions={closeMentions}
                                            options={filteredAgents}
                                        />
                                    )}
                                </Box>
                                {initialValues.section && (
                                    <Box sx={{mt: 1}}>
                                        <Field
                                            type="checkbox"
                                            name="isPublic"
                                            label={t('orguser.requesterCase.comments.visibleForRequester')}
                                            component={FormBuilderCheckboxReverse}
                                        />
                                    </Box>
                                )}
                                {inStack && (
                                    <Box sx={{mt: 1}}>
                                        <Field
                                            type="checkbox"
                                            name="forStack"
                                            label={t('common.buttons.postForStack')}
                                            component={FormBuilderCheckboxReverse}
                                        />
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: isModal ? 'flex-end' : 'flex-start',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        mt: 3,
                                    }}
                                >
                                    {!isModal && (
                                        <AgentInverseButton title={t('common.buttons.close')} onClick={closeForm} />
                                    )}
                                    <AgentSaveButton isSubmit />
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </ClickAwayListener>
    );
};

export default CommentForm;
