import React, {FC, useContext, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RequesterSignatureResponseType} from 'appRedux/actions/profile/types';
import {RootReducer} from 'appRedux/reducers';

import {CaseKeyContext} from 'contexts/caseKey/context';

import {RESOURCE_FIELD_TYPE_CALENDAR} from 'components/Forms/ResourceField/helper';

import {
    CONTENT_FIELD,
    CONTENT_POPUP,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DATE_RANGE,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_UPLOADER,
    FORM_FIELD_RESOURCE_FIELD,
    SectionContentType,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import UploadedFilesContent from 'pages/agent/requesterPage/sections/UploadedFilesContent';
import PopupInformation from 'pages/agent/requesterPage/sections/PopupInformation';
import SelectedOption from 'pages/agent/requesterPage/sections/SelectedOption';
import {getSelectedOptionsList} from 'pages/agent/requesterPage/sections/helper';
import InformationWrapperWithTranslation from 'pages/agent/requesterPage/wrappers/InformationWrapperWithTranslation';
import RequesterCaseHasSlotInformation from 'pages/agent/requesterPage/resourceFields/RequesterCaseHasSlotInformation';
import RequesterCaseHasCalendarInformation from 'pages/agent/requesterPage/resourceFields/RequesterCaseHasCalendarInformation';
import RequesterCaseHasSelectedSlotsInformation from 'pages/agent/requesterPage/resourceFields/RequesterCaseHasSelectedSlotsInformation';
import RequesterCaseHasBudgetInformation from 'pages/agent/requesterPage/resourceFields/RequesterCaseHasBudgetInformation';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';
import {getFieldKeyword, getPopupKeyword, getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';
import getFieldMaxValue from 'helpers/getFieldMaxValue';

import {EMPTY_DATA} from 'config/index';
import SignaturesContent from './SignaturesContent';

interface SectionContentItemType {
    formId: number;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
    content: RequesterCaseItemType;
    item: SectionContentType;
    section: FormSectionTypes;
}

const SectionContentItem: FC<SectionContentItemType> = ({
    formId,
    item,
    content,
    clientTranslations,
    setClientTranslations,
    showTranslation,
    editTranslation,
    section,
}) => {
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [text, setText] = useState<string>('-');

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {files, signatures, isEncryptInfo, options, popupItems},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {fields, popups, id} = section;

    const field = get(fields, item.index, null);
    const popup = get(popups, item.index, null);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (content && field) {
                let value = String(get(content, `field-${field.id}`, EMPTY_DATA));
                if (isEncryptInfo && unwrappedCaseKey) {
                    value = await decryptStringWithKey(value, unwrappedCaseKey);
                }
                setText(value);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [content]);

    if (item.type === CONTENT_FIELD) {
        if (!field) return null;
        const keywordTitle = getFieldKeyword(formId, field.id, 'label');
        const keywordDescription = getFieldKeyword(formId, field.id, 'description');

        if (field.type === FORM_FIELD_UPLOADER) {
            return (
                <UploadedFilesContent
                    formId={formId}
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                    files={files}
                    sectionId={id}
                />
            );
        }
        if ([FORM_FIELD_DROPDOWN, FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS].includes(fields[item.index]?.type)) {
            const selectedOptions = field.fieldOptions ? getSelectedOptionsList(field.fieldOptions, options) : [];
            return (
                <InformationWrapper
                    title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                    description={getFormTranslatedLabel(translations, keywordDescription, field.label)}
                >
                    {selectedOptions.map(option => {
                        return (
                            <SelectedOption
                                field={field}
                                formId={formId}
                                option={option}
                                key={`option-${option.uuid}`}
                                clientText={text}
                            />
                        );
                    })}
                </InformationWrapper>
            );
        }
        if ([FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(fields[item.index]?.type)) {
            const selectedOptions = field.fieldOptions ? getSelectedOptionsList(field.fieldOptions, options) : [];
            return (
                <InformationWrapper
                    title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                    description={getFormTranslatedLabel(translations, keywordDescription, field.label)}
                >
                    {selectedOptions.map(option => {
                        const keywordOptionTitle = getOptionKeyword(formId, option.id, 'title');
                        const title = getFormTranslatedLabel(translations, keywordOptionTitle, option.title);
                        return (
                            <Box sx={{width: '100%'}} key={option.id}>
                                - {title}
                            </Box>
                        );
                    })}
                </InformationWrapper>
            );
        }
        if (field.type === FORM_FIELD_SIGNATURE) {
            return (
                <SignaturesContent
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                    sectionId={id}
                    signatures={signatures}
                />
            );
        }

        if (field.type === FORM_FIELD_RESOURCE_FIELD && field.resourceFieldType === RESOURCE_FIELD_TYPE_CALENDAR) {
            return (
                <RequesterCaseHasCalendarInformation
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                />
            );
        }
        if (field.type === FORM_FIELD_RESOURCE_FIELD && field.resourceFieldBudget) {
            return (
                <RequesterCaseHasBudgetInformation
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                />
            );
        }
        if (field.type === FORM_FIELD_RESOURCE_FIELD && field.resourceFieldSlot) {
            return (
                <RequesterCaseHasSlotInformation
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                />
            );
        }
        if (field.type === FORM_FIELD_RESOURCE_FIELD) {
            return (
                <RequesterCaseHasSelectedSlotsInformation
                    field={field}
                    keywordTitle={keywordTitle}
                    keywordDescription={keywordDescription}
                />
            );
        }

        const maxValue = getFieldMaxValue(field.type, field.options);

        let formattedText = text.replace(/<[^>]*>/g, '') + (maxValue || '');

        if (field.type === FORM_FIELD_DATE_RANGE) {
            formattedText = formattedText.replace('&', ' - ');
        }

        return (
            <InformationWrapperWithTranslation
                sectionId={id}
                field={field}
                showTranslation={showTranslation}
                editTranslation={editTranslation}
                clientTranslations={clientTranslations}
                setClientTranslations={setClientTranslations}
                title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
                description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
                currentValue={text}
            >
                <Typography>{formattedText}</Typography>
            </InformationWrapperWithTranslation>
        );
    }
    if (item.type === CONTENT_POPUP) {
        if (!popup || !popupItems) return null;
        const keywordTitle = getPopupKeyword(formId, popup.id, 'title');
        const keywordDescription = getFieldKeyword(formId, popup.id, 'description');

        const submittedPopupItems = popupItems.filter(item => item.isSubmitted && item.popupId === popup.id);
        return (
            <InformationWrapper
                title={getFormTranslatedLabel(translations, keywordTitle, popup.title)}
                description={getFormTranslatedLabel(translations, keywordDescription, popup.description)}
            >
                <PopupInformation
                    formId={formId}
                    sectionId={id}
                    popup={popup}
                    items={submittedPopupItems}
                    showTranslation={showTranslation}
                    editTranslation={editTranslation}
                    clientTranslations={clientTranslations}
                    setClientTranslations={setClientTranslations}
                />
            </InformationWrapper>
        );
    }
    return null;
};

export default SectionContentItem;
