import {TFunction} from 'i18next';
import moment from 'moment';

import {RequesterCaseHasCalendarTypes, CalendarTimeRanges} from 'appRedux/actions/requestCase/types';
import {
    ResourceFieldBookframeItemTypes,
    AvailableBookframeItemTypes,
    ResourceFieldExceptionItemTypes,
} from 'appRedux/actions/resourceFields/types';

import {TabItemType} from 'components/TabsWrapper/helper';
import {
    getResourceFieldWeekdayLabel,
    CALENDAR_EXCEPTION_BLOCKED_DATE,
    CALENDAR_EXCEPTION_CHANGED_TIMES,
} from 'components/Forms/ResourceField/helper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';
import {convertTimeToSeconds, convertSecondsToTime} from 'helpers/dateTimeConvertingHelper';

import {DATE_TIME_LABEL_FORMAT, DATE_FORMAT_FILTER, STATUS_OPENING} from 'config/index';
import {theme, MAIN_CLIENT_COLOR} from 'config/theme';

export const TAB_STEP_ONE = 1;
export const TAB_STEP_TWO = 2;
export const TAB_STEP_THREE = 3;
export const TAB_STEP_FOUR = 4;

export const getTabsList = (currentCalendar?: RequesterCaseHasCalendarTypes): TabItemType[] => {
    return [
        {
            title: 'requester.casePage.calendarSteps.stepOne',
            tabNumber: TAB_STEP_ONE,
            isDisabled: false,
        },
        {
            title: 'requester.casePage.calendarSteps.stepTwo',
            tabNumber: TAB_STEP_TWO,
            isDisabled: !currentCalendar || !currentCalendar.resourceFieldCalendar,
        },
        {
            title: 'requester.casePage.calendarSteps.stepThree',
            tabNumber: TAB_STEP_THREE,
            isDisabled: !currentCalendar || !currentCalendar.resourceFieldCalendarSlot,
        },
        {
            title: 'requester.casePage.calendarSteps.stepFour',
            tabNumber: TAB_STEP_FOUR,
            isDisabled: !currentCalendar || !currentCalendar.calendarDate,
        },
    ];
};

export const getInitialStepTab = (currentCalendar?: RequesterCaseHasCalendarTypes): number => {
    if (
        currentCalendar &&
        (currentCalendar.resourceFieldCalendarBookframe || currentCalendar.resourceFieldCalendarException)
    ) {
        return TAB_STEP_FOUR;
    }
    if (currentCalendar && currentCalendar.resourceFieldCalendarSlot) {
        return TAB_STEP_THREE;
    }
    if (currentCalendar && currentCalendar.resourceFieldCalendar) {
        return TAB_STEP_TWO;
    }
    return TAB_STEP_ONE;
};

export const getCalendarStepTitle = (step: number, currentCalendar?: RequesterCaseHasCalendarTypes): string => {
    switch (step) {
        case TAB_STEP_ONE:
            return 'requester.casePage.calendarStepTitles.stepOne';
        case TAB_STEP_TWO:
            return 'requester.casePage.calendarStepTitles.stepTwo';
        case TAB_STEP_THREE:
            return 'requester.casePage.calendarStepTitles.stepThree';
        case TAB_STEP_FOUR:
            return currentCalendar ? getCalendarStatusLabel(currentCalendar.status) : '';
        default:
            return '';
    }
};

export const REQUESTER_CASE_HAS_CALENDAR_CREATED = 0;
export const REQUESTER_CASE_HAS_CALENDAR_RESERVED = 1;
export const REQUESTER_CASE_HAS_CALENDAR_BOOKED = 2;
export const REQUESTER_CASE_HAS_CALENDAR_CLEARED = 3;

export const getCalendarStatusLabel = (status: number): string => {
    switch (status) {
        case REQUESTER_CASE_HAS_CALENDAR_CREATED:
            return 'requester.casePage.calendarStatus.created';
        case REQUESTER_CASE_HAS_CALENDAR_RESERVED:
            return 'requester.casePage.calendarStatus.reserved';
        case REQUESTER_CASE_HAS_CALENDAR_BOOKED:
            return 'requester.casePage.calendarStatus.booked';
        case REQUESTER_CASE_HAS_CALENDAR_CLEARED:
            return 'requester.casePage.calendarStatus.cleared';
    }
    return '';
};

export const getCalendarStatusDateTime = (budget: RequesterCaseHasCalendarTypes): string => {
    const {status, createdAt, reservedAt, bookedAt, clearedAt} = budget;
    switch (status) {
        case REQUESTER_CASE_HAS_CALENDAR_CREATED:
            return createdAt ? convertDateToCurrentLocale(new Date(createdAt), false, true) : '';
        case REQUESTER_CASE_HAS_CALENDAR_RESERVED:
            return reservedAt ? convertDateToCurrentLocale(new Date(reservedAt), false, true) : '';
        case REQUESTER_CASE_HAS_CALENDAR_BOOKED:
            return bookedAt ? convertDateToCurrentLocale(new Date(bookedAt), false, true) : '';
        case REQUESTER_CASE_HAS_CALENDAR_CLEARED:
            return clearedAt ? convertDateToCurrentLocale(new Date(clearedAt), false, true) : '';
    }
    return '';
};

export const getDateSelectionPossibleWeekdays = (bookframes: ResourceFieldBookframeItemTypes[]): number[] => {
    const results: number[] = [];
    bookframes.forEach(item => {
        results.push(item.weekday);
    });
    return results;
};

export const getDateSelectionPossibleWeekdaysString = (t: TFunction, weekdays: number[]): string => {
    const results: string[] = [];
    weekdays.forEach(item => {
        const result = t(getResourceFieldWeekdayLabel(item));
        if (!results.includes(result)) {
            results.push(result);
        }
    });
    return results.join(', ');
};

export const getAvailableBookframes = (
    bookframes: ResourceFieldBookframeItemTypes[],
    selectedDate: Date,
    slotDuration: number,
    changedDatesExceptions: ResourceFieldExceptionItemTypes[],
): AvailableBookframeItemTypes[] => {
    const results: AvailableBookframeItemTypes[] = [];

    const selectedWeekday = selectedDate.getDay();

    const changedDatesExceptionsForSelectedDate = changedDatesExceptions.filter(
        item => moment(item.date).format(DATE_FORMAT_FILTER) === moment(selectedDate).format(DATE_FORMAT_FILTER),
    );

    if (changedDatesExceptionsForSelectedDate.length > 0) {
        changedDatesExceptionsForSelectedDate.forEach((exception: ResourceFieldExceptionItemTypes, index: number) => {
            const {duration, startTime, endTime, id, uuid} = exception;
            if (duration && duration >= slotDuration && startTime && endTime) {
                const startTimeSeconds = convertTimeToSeconds(startTime);
                const endTimeSeconds = convertTimeToSeconds(endTime);
                let slotStartTime = startTimeSeconds;
                while (slotStartTime + slotDuration * 60 <= endTimeSeconds) {
                    const slotEndTime = slotStartTime + slotDuration * 60;
                    if (slotEndTime <= endTimeSeconds) {
                        results.push({
                            isException: true,
                            id,
                            duration,
                            slotStartTime: convertSecondsToTime(slotStartTime),
                            slotEndTime: convertSecondsToTime(slotEndTime),
                            uuid,
                        });
                        slotStartTime = slotEndTime;
                    }
                }
            }
        });
    } else {
        bookframes.forEach((bookframe: ResourceFieldBookframeItemTypes) => {
            const {duration, startTime, endTime, weekday} = bookframe;
            if (duration >= slotDuration && weekday === selectedWeekday) {
                const startTimeSeconds = convertTimeToSeconds(startTime);
                const endTimeSeconds = convertTimeToSeconds(endTime);
                let slotStartTime = startTimeSeconds;
                while (slotStartTime + slotDuration * 60 <= endTimeSeconds) {
                    const slotEndTime = slotStartTime + slotDuration * 60;
                    if (slotEndTime <= endTimeSeconds) {
                        results.push({
                            ...bookframe,
                            isException: false,
                            slotStartTime: convertSecondsToTime(slotStartTime),
                            slotEndTime: convertSecondsToTime(slotEndTime),
                        });
                        slotStartTime = slotEndTime;
                    }
                }
            }
        });
    }

    return results;
};

export const isBeforeAndNotToday = (currentDate: string): boolean => {
    const isBefore = moment(currentDate).isBefore(new Date());
    const isToday = moment(currentDate).isSame(new Date(), 'day');
    return isBefore && !isToday;
};

export const isBeforeDateAndTime = (itemDate: string, slotStartTime: string): boolean => {
    const currentDate = moment(itemDate).format(DATE_FORMAT_FILTER);
    const isBefore = moment(itemDate).isBefore(new Date());
    const isToday = moment(itemDate).isSame(new Date(), 'day');
    return (
        isBeforeAndNotToday(currentDate) || (isToday && moment(`${currentDate} ${slotStartTime}`).isBefore(new Date()))
    );
};

export const isBlockedDate = (blockedDatesExceptions: ResourceFieldExceptionItemTypes[], itemDate: string): boolean => {
    return blockedDatesExceptions.some(exception => exception.date === moment(itemDate).format(DATE_FORMAT_FILTER));
};

export const isCalendarBookframeItemUnavailable = (
    itemDate: string,
    slotStartTime: string,
    slotEndTime: string,
    blockedDatesExceptions: ResourceFieldExceptionItemTypes[],
    unavailableCalendarRanges: CalendarTimeRanges[],
): boolean => {
    if (isBeforeDateAndTime(itemDate, slotStartTime) || isBlockedDate(blockedDatesExceptions, itemDate)) {
        return true;
    }

    const slotStartTimeSeconds = convertTimeToSeconds(slotStartTime);
    const slotEndTimeSeconds = convertTimeToSeconds(slotEndTime);
    for (let i = 0, n = unavailableCalendarRanges.length; i < n; i++) {
        const item = unavailableCalendarRanges[i];
        const {date, startTime, endTime} = item;
        if (date === moment(itemDate).format(DATE_FORMAT_FILTER)) {
            const startTimeSeconds = convertTimeToSeconds(startTime);
            const endTimeSeconds = convertTimeToSeconds(endTime);
            if (
                (startTimeSeconds <= slotStartTimeSeconds && endTimeSeconds > slotStartTimeSeconds) ||
                (startTimeSeconds >= slotStartTimeSeconds && endTimeSeconds <= slotEndTimeSeconds) ||
                (startTimeSeconds < slotEndTimeSeconds && endTimeSeconds >= slotEndTimeSeconds)
            ) {
                return true;
            }
        }
    }
    return false;
};

export const getCalendarBookframeItemBackgroundColor = (isAlreadySelected: boolean, isDisabled: boolean): string => {
    if (isAlreadySelected) {
        return MAIN_CLIENT_COLOR;
    }
    if (isDisabled) {
        return theme.palette.warning.contrastText;
    }
    return theme.palette.secondary.contrastText;
};

export const getCalendarBookframeItemHoverBackgroundColor = (
    isAlreadySelected: boolean,
    isTimeRangeDisabled: boolean,
    isActionDisabled: boolean,
): string => {
    if (isAlreadySelected) {
        return MAIN_CLIENT_COLOR;
    }
    if (isTimeRangeDisabled) {
        return theme.palette.warning.contrastText;
    }
    if (!isActionDisabled) {
        return theme.palette.info.main;
    }
    return theme.palette.secondary.contrastText;
};

export const getCalendarBookframeItemTextColor = (isAlreadySelected: boolean, isTimeRangeDisabled: boolean): string => {
    if (isTimeRangeDisabled) {
        return theme.palette.secondary.contrastText;
    }
    if (isAlreadySelected) {
        return theme.palette.background.default;
    }
    return theme.palette.info.main;
};

export const getCalendarBookframeItemHoverTextColor = (
    isAlreadySelected: boolean,
    isTimeRangeDisabled: boolean,
    isActionDisabled: boolean,
): string => {
    if (isTimeRangeDisabled) {
        return theme.palette.secondary.contrastText;
    }
    if (!isActionDisabled || isAlreadySelected) {
        return theme.palette.background.default;
    }
    return theme.palette.info.main;
};

export const isChangedDate = (exceptions: ResourceFieldExceptionItemTypes[], date: Date): boolean => {
    for (let i = 0, n = exceptions.length; i < n; i++) {
        if (exceptions[i].date === moment(date).format('YYYY-MM-DD')) {
            return true;
        }
    }
    return false;
};

export const isSelectedDateExcepted = (
    currentDateObject: Date,
    exceptions: ResourceFieldExceptionItemTypes[],
): boolean => {
    return exceptions.some(item => {
        if (item.type === CALENDAR_EXCEPTION_BLOCKED_DATE) {
            return item.date === moment(currentDateObject).format('DD/MM/YYYY');
        }
        return false;
    });
};

export const checkIsWeekdayAvailable = (
    possibleWeekdays: number[],
    changedDatesExceptions: ResourceFieldExceptionItemTypes[],
    date: Date,
): boolean => {
    return possibleWeekdays.includes(date.getDay()) || isChangedDate(changedDatesExceptions, date);
};

export const checkIsPreviousButtonDisabled = (weekDates: Date[]): boolean => {
    const firstWeekDate = moment(weekDates[0]).format(DATE_FORMAT_FILTER);
    return moment(firstWeekDate).add(-1, 'day').isBefore(new Date());
};
