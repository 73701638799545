import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import TicketCreationForm from 'components/Forms/RequesterCase/TicketCreationForm';

import {theme} from 'config/theme';

const AddTicketButton: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('orguser.forms.addTicket')}
                onClick={() => setIsOpened(true)}
            >
                <PlusSvgIcon isActive />
            </IconButton>
            <ModalWrapper isShowModal={isOpened} title={t('orguser.forms.addTicket')} toggleModal={toggleModal}>
                <TicketCreationForm closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default AddTicketButton;
