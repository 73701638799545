import React, {FC, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import {getPageKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';
import {PARAMETER_PAGE_ID, PARAMETER_SECTION_ID} from 'config/index';

interface PageSwitcherType {
    formId: number;
    pages: FormPageTypes[];
}

const PageSwitcher: FC<PageSwitcherType> = ({formId, pages}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {formPage, setFormPage} = useContext(RouteContext);

    const changePageClicked = (pageNumber: number) => {
        setFormPage(pageNumber);
        searchParams.delete(PARAMETER_PAGE_ID);
        searchParams.delete(PARAMETER_SECTION_ID);
        setSearchParams(searchParams);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'row', mb: 1}}>
            {pages.map((page: FormPageTypes, index: number) => {
                const isActive = formPage === index + 1;
                const keyword = getPageKeyword(formId, page.id, page.versionId, 'title');
                const pageTitle = getFormTranslatedLabel(translations, keyword, page.title);
                return (
                    <Box
                        key={`page-${index}`}
                        sx={{
                            position: 'relative',
                            cursor: 'pointer',
                            height: 40,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            backgroundColor: isActive ? theme.palette.info.main : `rgba(34, 34, 34, 0.2)`,
                            mr: 1,
                            pt: 2,
                            pb: 2,
                            flex: '1 1 10px',
                            maxWidth: 180,
                        }}
                        onClick={() => changePageClicked(index + 1)}
                    >
                        {page.isSubFormPage && (
                            <Tooltip
                                title={`${t('orguser.forms.subForm')}: ${page.subFormTitle}`}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            fontSize: 16,
                                            backgroundColor: 'rgb(97, 97, 97)',
                                        },
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: -5,
                                        right: 10,
                                        width: 14,
                                        height: 14,
                                        borderRadius: 7,
                                        backgroundColor: MAIN_CLIENT_COLOR,
                                    }}
                                ></Box>
                            </Tooltip>
                        )}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                pl: 1,
                                pr: 1,
                            }}
                        >
                            <Tooltip
                                title={pageTitle}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            fontSize: 16,
                                            backgroundColor: 'rgb(97, 97, 97)',
                                        },
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 200,
                                        fontWeight: 600,
                                        lineHeight: '22px',
                                        color: isActive ? theme.palette.background.default : theme.palette.info.main,
                                    }}
                                >
                                    {pageTitle}
                                </Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default PageSwitcher;
