import {
    WorkflowStatusRuleApproverItemTypes,
    WorkflowStatusRuleApproversGroupItemTypes,
    WorkflowStatusRuleItemTypes,
} from 'appRedux/actions/workflow/types';

import {WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED} from 'components/Forms/Workflow/workflowStatusRule/helper';

export const checkIsCurrentUserCanApproveStatusChanging = (
    rules: WorkflowStatusRuleItemTypes[],
    currentUserId: number,
): boolean => {
    const approvers: WorkflowStatusRuleApproverItemTypes[] = [];
    const groups: WorkflowStatusRuleApproversGroupItemTypes[] = [];
    rules.forEach(rule => {
        if (rule.type === WORKFLOW_STATUS_RULE_TYPE_APPROVAL_REQUIRED) {
            const currentRuleApprovers: WorkflowStatusRuleApproverItemTypes[] = rule.approvers;
            const currentRuleGroups: WorkflowStatusRuleApproversGroupItemTypes[] = rule.groups;
            currentRuleApprovers.forEach(currentRuleApprover => {
                if (!approvers.some(approver => approver.id === currentRuleApprover.id)) {
                    approvers.push(currentRuleApprover);
                }
            });
            currentRuleGroups.forEach(currentRuleGroup => {
                if (!groups.some(group => group.id === currentRuleGroup.id)) {
                    groups.push(currentRuleGroup);
                }
            });
        }
    });

    const isCurrentUserPresentedInApproversList = approvers.some(item => Number(item.id) === currentUserId);
    let isCurrentUserPresentedInGroupsApproversList = false;
    for (let i = 0, n = groups.length; i < n; i++) {
        const group = groups[i];
        if (group && group.agents && group.agents.some(agent => agent.id === currentUserId)) {
            isCurrentUserPresentedInGroupsApproversList = true;
        }
    }
    return isCurrentUserPresentedInApproversList || isCurrentUserPresentedInGroupsApproversList;
};
