import React, {FC, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';

import {FORM_FIELD_DATE_RANGE} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {isSelectionFields, OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import getFieldMaxValue from 'helpers/getFieldMaxValue';

import {EMPTY_DATA} from 'config/index';

interface PopupFieldPrintContentType {
    pageId: number;
    sectionId: number;
    popupId: number;
    item: RequesterCasePopupType;
    fields: FormFieldTypes[];
}

const PopupFieldPrintContent: FC<PopupFieldPrintContentType> = ({fields, item}) => {
    const {formId} = useParams();

    const {isMobile} = useContext(MediaContext);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const content = item.result && JSON.parse(item.result);

    return (
        <Box>
            {fields.map((field: FormFieldTypes, index: number) => {
                const {id, label, type, options} = field;
                const keyword = getFieldKeyword(Number(formId), id, 'label');
                const value = get(content, `field-${field.id}`, EMPTY_DATA);

                const maxValue = getFieldMaxValue(type, options);

                let formattedText = String(value).replace(/<[^>]*>/g, '') + (maxValue || '');
                if (type === FORM_FIELD_DATE_RANGE) {
                    formattedText = formattedText.replace('&', ' - ');
                }

                return (
                    <Grid sx={{mb: 1}} key={`popup-field-${index}`} container>
                        <Grid item sm={3}>
                            <Typography sx={{fontSize: 16, fontWeight: 600}}>
                                {`${getFormTranslatedLabel(translations, keyword, label)}:`}
                            </Typography>
                        </Grid>
                        <Grid item sm={9}>
                            <Typography sx={{fontSize: isMobile ? 12 : 14, mt: 0.5}}>
                                {isSelectionFields(type)
                                    ? String(formattedText).replaceAll(OPTIONS_SEPARATOR, ', ')
                                    : String(formattedText)}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
};

export default PopupFieldPrintContent;
