import moment from 'moment';

export const protectedRoutes = {
    UI: 'ui',
    LOGIN_SSO: 'sso',
    API: 'api',
    FORM: 'form',
    LOGIN: 'login',
    AUTH_SSO: 'login_sso',
    INVITE: 'invite',
    REQUESTER_CASE: 'requesterCase',
    REQUEST: 'request',
};

export const paths = {
    START: '/',
    START_NO_SSO: '/no-sso',
    REDIRECT: '/redirect',
    INVITE: '/invite',
    INVITE_ADDITIONAL: '/additional',
    LOGIN_SSO: '/sso',
    AUTH_SSO: '/login_sso',
    REGISTRATION_SSO: '/registration_sso',
    SELECT_DEFAULT_ROLE: '/defaultRole',
    VERIFICATION: '/verify',

    FORGET_NICKNAME: '/forget-nickname',
    SET_PASSWORD: '/set-password',
    RECOVERY_PASSWORD: '/recovery-password',
    RESET_PASSWORD: '/reset-password',
    EMAIL_UPDATE: '/email/update',

    RULE_ROUTE_IMPRINT: '/imprint',
    RULE_ROUTE_PRIVACY_POLICY: '/privacyPolicy',

    ACCOUNT: '/account',
    INTERACTIVE_REQUEST: '/interactiveRequest',
    SECURITY: '/security',
    SETTINGS: '/settings',
    REQUEST: '/request',
    REQUESTS_LIST: '/myRequests',
    TICKET: '/ticket',
    FORMS_LIST_REQUESTER: '/organizationForms',
    INVITES_LIST_REQUESTER: '/invites',
    FINAL_PAGE: '/final',
    GENERAL: '/general',

    ADMIN: '/admin',
    ADMIN_ACCOUNT: '/admin/account',
    ADMIN_SECURITY: '/admin/security',
    ADMIN_SETTINGS: '/admin/settings',
    ADD_FORM: '/admin/form/create',
    UPDATE_FORM: '/admin/form',

    AGENT: '/agent',
    AGENT_ACCOUNT: '/agent/account',
    AGENT_SECURITY: '/agent/security',
    AGENT_SETTINGS: '/agent/settings',
    REQUESTERS: '/agent/requesters',
    AGENT_PRINT: '/agent/print',

    SUPER_ADMIN: '/super-admin',
    SUPER_ADMIN_ACCOUNT: '/super-admin/account',
    SUPER_ADMIN_SECURITY: '/super-admin/security',
    SUPER_ADMIN_SETTINGS: '/super-admin/settings',
    ADD_ORGANIZATION: '/super-admin/organization/create',
    UPDATE_ORGANIZATION: '/super-admin/organization',

    BOARD: '/board',
    SWIMLANE: '/swimlane',
    DASHBOARD: '/dashboard',
    LIST: '/boardList',
    BOARD_OVERVIEW: '/boardOverview',
    ROLE: '/role',
    GROUP: '/group',
    FORM: '/forms',
    FORM_TRANSLATIONS: '/formTranslations',
    REQUESTER_CASE: '/requesterCase',
    CASE_ACCESS_REQUEST: '/accessRequests',
    SAVED_FILTERS: '/filters',
    RESOURCE_FIELD: '/resourceField',
    WORKFLOW: '/workflow',
    PDF_TEMPLATE: '/pdfTemplate',
};

export const routes = (() => {
    const routesObj = {...paths};
    for (const key in paths) {
        switch (key) {
            case 'START':
                routesObj[key] = paths[key];
                break;
            default:
                routesObj[key] = `/${protectedRoutes.UI}${paths[key]}`;
        }
    }

    return routesObj;
})();

export const TWO_FACTOR_CONFIRMATION_URL = 'user/two-factor-confirm';

export const userRoles = {
    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    ROLE_AGENT: 'ROLE_AGENT',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_REQUEST: 'ROLE_REQUEST',
};

export const TYPE_MULTI_ROLE_UNDEFINED = 0;
export const TYPE_MULTI_ROLE_ORGANIZATION_USER = 1;
export const TYPE_MULTI_ROLE_REQUESTER = 2;

export const STATUS_OPENING = 'draft';
export const STATUS_PREPARED = 'submitted';
export const STATUS_REVIEW = 'review';
export const STATUS_DECLINED = 'declined';
export const STATUS_FORWARDED = 'forwarded';
export const STATUS_HELPED = 'helped';
export const STATUS_DELETED = 'deleted';
export const STATUS_ARCHIVED = 'archived';

export const requesterStatuses = [
    STATUS_OPENING,
    STATUS_PREPARED,
    STATUS_REVIEW,
    STATUS_FORWARDED,
    STATUS_DECLINED,
    STATUS_HELPED,
    STATUS_DELETED,
];

export const FILE_TYPE_DEFAULT = 0;
export const FILE_TYPE_PROOF = 1;
export const FILE_TYPE_SAMPLE = 2;
export const FILE_TYPE_DOCUMENT = 3;

export const WRONG_LOGO_TYPE = 'WRONG_LOGO_TYPE';
export const WRONG_LOGO_SIZE = 'WRONG_LOGO_SIZE';
export const WRONG_FAVICON_SIZE = 'WRONG_FAVICON_SIZE';

export const documentIdOptions: {[key: string]: {label: string; value: number | string}[]} = {
    documentId: [
        {
            label: 'labelId',
            value: 1,
        },
        {
            label: 'labelPassport',
            value: 2,
        },
    ],
};

export const DEFAULT_TOP_BAR_LABEL = 'Herupu';

export const DEFAULT_LANGUAGE_ID = 1;
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LANGUAGE_LABEL = 'English';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_FILTER = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_LABEL_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_LABEL_FORMAT = 'DD MMM YYYY, HH:mm';
export const DATE_FORMAT_CLIENT = 'DD.MM.YYYY';
export const TIME_FORMAT_CLIENT = 'HH:mm';
export const DATEPICKER_FORMAT = 'dd/MM/yyyy';
export const EMPTY_DATA = ' - ';

export const PARAMETER_PAGE = 'page';
export const PARAMETER_PER_PAGE = 'perPage';
export const PARAMETER_TAB = 'tab';
export const PARAMETER_AGENT = 'agent';
export const PARAMETER_STATUS = 'status';
export const PARAMETER_ROLE = 'role';
export const PARAMETER_ORGANIZATION = 'organization';
export const PARAMETER_FORM = 'form';
export const PARAMETER_LANGUAGE = 'language';
export const PARAMETER_SEARCH = 'search';
export const PARAMETER_DATE_FROM = 'date_from';
export const PARAMETER_DATE_TO = 'date_to';
export const PARAMETER_TAG = 'tags';
export const PARAMETER_FIRST_LETTER = 'firstLetter';
export const PARAMETER_LAST_CREATED = 'lastCreated';
export const PARAMETER_LAST_UPDATED = 'lastUpdated';
export const PARAMETER_LAST_NAME = 'lastName';
export const PARAMETER_OPTION = 'options';
export const PARAMETER_VIEW_MODE = 'viewMode';
export const PARAMETER_UPDATED_AT = 'updatedAt';
export const PARAMETER_PAGE_ID = 'pageId';
export const PARAMETER_MESSAGE_ID = 'messageId';
export const PARAMETER_SECTION_ID = 'sectionId';
export const PARAMETER_SUB = 'sub';
export const PARAMETER_TICKET = 'ticket';
export const PARAMETER_TITLE = 'title';
export const PARAMETER_ID_TOKEN = 'id_token';
export const PARAMETER_COLUMN_SIZE = 'columnSize';
export const PARAMETER_CURRENT_BOARD = 'currentBoard';

export const PARAMETER_SORT_BY = 'sortBy';
export const PARAMETER_SORT_TYPE = 'sortType';
export const SORTING_ASC = 'ASC';
export const SORTING_DESC = 'DESC';

export const DEFAULT_PAGE = 1;
export const DEFAULT_FILTER_OPTION = 'all';
export const DEFAULT_FIRST_LETTER = 'A';
export const DEFAULT_VALUE = '-';
export const DEFAULT_NONE = 'none';
export const ITEMS_PER_PAGE_DEFAULT = 10;
export const ITEMS_PER_PAGE_LARGE_DESKTOP = 50;
export const ITEMS_PER_PAGE_DESKTOP = 25;
export const ITEMS_PER_PAGE_BOARD = 25;
export const ITEMS_PER_PAGE_FOR_CASES = 10;
export const ITEMS_PER_PAGE_OPTIONS_DEFAULT = [10, 25];
export const DEFAULT_FILTER_DATE_FROM = moment().subtract(7, 'd').toDate();
export const DEFAULT_AGENT_FILTER_DATE_FROM = moment().subtract(14, 'd').toDate();
export const DEFAULT_FILTER_DATE_TO = moment().toDate();
export const COLUMN_SIZE_OPTIONS_DEFAULT = [
    {value: 210, label: 'small'},
    {value: 290, label: 'medium'},
    {value: 370, label: 'large'},
];

export const MAXIMAL_FORM_PAGES_NUMBER = 100;

export const MINIMAL_HEIGHT_FOR_READ_MORE = 80;

export const QR_CODE_SIZE = 256;
export const QR_CODE_WIDTH = 300;
export const QR_CODE_HEIGHT = 300;
export const QR_CODE_VERIFICATION_MIN_LENGTH = 6;
export const QR_CODE_VERIFICATION_MAX_LENGTH = 12;

export const CRYPTO_PASSWORD_MIN_LENGTH = 8;
export const CRYPTO_PASSWORD_MAX_LENGTH = 24;

export const ALERT_TYPE_ERROR = 'ALERT_TYPE_ERROR';
export const ALERT_TYPE_SUCCESS = 'ALERT_TYPE_SUCCESS';
export const ALERT_TYPE_INFO = 'ALERT_TYPE_INFO';

export const ALERT_SUCCESS_MESSAGE = 'messages.success.alertSuccessMessage';
export const ALERT_SUCCESS_MESSAGE_2FA_REGENERATED = 'messages.success.twoFactorRegeneratedSuccessfully';
export const ALERT_SUCCESS_MESSAGE_2FA_ACTIVATED = 'messages.success.twoFactorActivatedSuccessfully';
export const ALERT_SUCCESS_MESSAGE_2FA_DEACTIVATED = 'messages.success.twoFactorDeactivatedSuccessfully';
export const ALERT_SUCCESS_MESSAGE_SEND_AGAIN = 'messages.success.sendAgainSuccessMessage';

export const REFRESH_TOKEN_NOTIFICATION_BEFORE = 120;
export const REFRESH_TOKEN_NOTIFICATION_INTERVAL = 30000;
export const REFRESH_TOKEN_AFTER_PENDING = 60;
export const CHECK_LOGOUT_TIME_INTERVAL = 2000;

export const MINIMAL_NICKNAME_LENGTH = 8;
export const MAXIMAL_NICKNAME_LENGTH = 50;

export const MAXIMAL_FIRSTNAME_LENGTH = 255;
export const MAXIMAL_LASTNAME_LENGTH = 255;

export const RULE_TYPE_IMPRINT = 1;
export const RULE_TYPE_PRIVACY_POLICY = 2;

export const AVATAR_SIZE = 45;

export const NOTIFICATION_TYPE_TRANSLATION_REQUEST = 1;
export const NOTIFICATION_TYPE_AGENT_ASSIGNED = 2;

export const ORGANIZATION_MEDIA_MAIN_LOGO = 1;
export const ORGANIZATION_MEDIA_NAVBAR_LOGO = 2;
export const ORGANIZATION_MEDIA_FAVICON = 3;

export const MESSAGES_PORTION = 50;

export const REQUEST_CASE_SMALL_BREAKPOINT = 250;
export const REQUEST_CASE_MEDIUM_BREAKPOINT = 345;
export const REQUEST_CASE_CHIP_BREAKPOINT = 330;

export const WEBSOCKET_NOTIFICATION_NEW_CHAT_MESSAGE = 'CHATMSG';
export const WEBSOCKET_NEW_NOTIFICATION = 'NOTIFICATION';
export const WEBSOCKET_NOTIFICATION_ANOTHER_LOGIN = 'NOTIFICATION_ANOTHER_LOGIN';
