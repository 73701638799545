import * as yup from 'yup';

import {REGEX_FOR_URL_KEYWORD} from 'components/Forms/FormBuilder/helper';

import {protectedRoutes} from 'config/index';

export const formInitialValues = {
    title: '',
    url: '',
    handlePrefix: '',
    description: '',
    formButtonText: '',
    policy: null,
    privacyPolice: '',
    submitMessage: '',
    isActive: false,
    isAnonymousRequestPossible: false,
    isOnlyInvite: false,
    isEmailEnabled: true,
    isEncryptInfo: false,
    isAutoChatEnabled: true,
    isChatActivated: true,
    isEmailsEnabled: true,
    isMultipleRequestPossible: false,
    isSubForm: false,
    isTicket: false,
    isShowInList: false,
    noEditForm: false,
    isIntegrationForm: false,
};

const FORM_TITLE_MINIMAL_LENGTH = 2;
const FORM_TITLE_MAXIMAL_LENGTH = 100;

export const formValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .min(
                FORM_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(FORM_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                FORM_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(FORM_TITLE_MAXIMAL_LENGTH)),
            )
            .required('messages.validation.isRequired'),
        workflow: yup.number().required('messages.validation.isRequired'),
        description: yup.string().nullable(),
        url: yup
            .string()
            .matches(REGEX_FOR_URL_KEYWORD, 'messages.validation.formUrlValidation')
            .required('messages.validation.isRequired')
            .test('test-route', '', function (value) {
                if (value && Object.values(protectedRoutes).includes(value)) {
                    return this.createError({
                        message: `Resticted keywords: ${Object.values(protectedRoutes).join(', ')}`,
                    });
                }
                return true;
            }),
        templatePdf: yup.number().required('messages.validation.isRequired'),
        policy: yup.number().nullable().required('messages.validation.isRequired'),
        privacyPolice: yup.string().nullable(),
    });

export const tickerFormValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .min(
                FORM_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(FORM_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                FORM_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(FORM_TITLE_MAXIMAL_LENGTH)),
            )
            .required('messages.validation.isRequired'),
        workflow: yup.number().required('messages.validation.isRequired'),
        description: yup.string().nullable(),
    });

export const subFormValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .min(
                FORM_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(FORM_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                FORM_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(FORM_TITLE_MAXIMAL_LENGTH)),
            )
            .required('messages.validation.isRequired'),
        description: yup.string().nullable(),
    });
