import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {GET_CURRENT_WORKFLOW_STATUS} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import StatusChangingApproveForm from 'components/Forms/RequesterCase/StatusChangingApproveForm';
import {
    CASE_STATUS_CHANGE_APPROVED,
    CASE_STATUS_CHANGE_REJECTED,
    CASE_STATUS_CHANGE_DEFAULT,
} from 'components/Forms/RequesterCase/helper';

import RightPanelSectionWrapper from 'pages/agent/requesterPage/wrappers/RightPanelSectionWrapper';
import {checkIsCurrentUserCanApproveStatusChanging} from 'pages/agent/requesterPage/approverResolution/helper';

const ApproverResolution: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {
            formInfo: {statuses},
        },
        profile: {profile},
        requestCase: {
            currentCase: {approves},
        },
        workflow: {currentWorkflowStatus},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getCurrentWorkflowStatusInfo = useCallback(
        data => dispatch({type: GET_CURRENT_WORKFLOW_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const currentUserId = profile ? profile.id : null;

    const notApprovedStatusChanges = currentUserId
        ? approves.filter(
              item => item.status === CASE_STATUS_CHANGE_DEFAULT && item.permittedApproverIds.includes(currentUserId),
          )
        : [];

    const notApprovedRequest = notApprovedStatusChanges.length > 0 ? notApprovedStatusChanges[0] : null;
    const approveWaitingStatus = notApprovedRequest
        ? statuses.find(item => item.uuid === notApprovedRequest.newStatusUuid)
        : null;

    const alreadyApprovedByCurrentUser = approves.some(
        item =>
            (item.status === CASE_STATUS_CHANGE_APPROVED || item.status === CASE_STATUS_CHANGE_REJECTED) &&
            Number(item.approverId) === Number(currentUserId) &&
            !item.isApproved,
    );

    useEffect(() => {
        if (approveWaitingStatus) {
            getCurrentWorkflowStatusInfo({
                uuid: approveWaitingStatus.uuid,
                showAlert,
            });
        }
    }, [approveWaitingStatus]);

    const isCurrentUserCanApproveStatusChanging = checkIsCurrentUserCanApproveStatusChanging(
        get(currentWorkflowStatus, 'rules', []),
        Number(currentUserId),
    );

    if (!isCurrentUserCanApproveStatusChanging || !notApprovedRequest || alreadyApprovedByCurrentUser) return null;

    return (
        <RightPanelSectionWrapper title={t('orguser.requesterCase.approves.approveStatusChanging')}>
            <StatusChangingApproveForm statusChangeRequest={notApprovedRequest} />
        </RightPanelSectionWrapper>
    );
};

export default ApproverResolution;
