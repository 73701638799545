import React, {FC, useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseHasTimeBucketTypes} from 'appRedux/actions/requestCase/types';

import {PermissionContext} from 'contexts/permission/context';

import TimeRecordItem from 'pages/agent/requesterPage/timeTracking/TimeRecordItem';
import AddTimeRecordButton from 'pages/agent/requesterPage/timeTracking/AddTimeRecordButton';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {convertNumToTimeString} from 'helpers/parseTimeString';

const TimeTracking: FC = () => {
    const [t] = useTranslation();

    const {formId} = useParams();

    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const {
        requestCase: {currentCaseTimeRecords},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    const groupedTimeBucketRecords = currentCaseTimeRecords.reduce(
        (acc: {[key: string]: RequesterCaseHasTimeBucketTypes[]}, record) => {
            const bucketTitle = record.bucketTitle || 'Other';
            if (!acc[bucketTitle]) {
                acc[bucketTitle] = [];
            }
            acc[bucketTitle].push(record);
            return acc;
        },
        {},
    );

    const [expandedBuckets, setExpandedBuckets] = useState<{[key: string]: boolean}>({});

    const toggleExpand = (bucketTitle: string) => {
        setExpandedBuckets(prevState => ({
            ...prevState,
            [bucketTitle]: !prevState[bucketTitle],
        }));
    };

    const loggedTime = currentCaseTimeRecords.reduce((acc, item) => acc + item.duration, 0);

    return (
        <Box sx={{mt: 1, mb: 1}}>
            <Box sx={{mb: currentCaseTimeRecords.length > 0 ? 1 : 0}}>
                <b>{t('orguser.requesterCase.timeTracking.total')}:</b> {convertNumToTimeString(loggedTime, t)}
                <ul style={{padding: '8px 0 8px 24px', margin: 0}}>
                    {currentCaseTimeRecords &&
                        Array.isArray(currentCaseTimeRecords) &&
                        Object.entries(groupedTimeBucketRecords).map(([bucketTitle, records]) => {
                            const totalDuration = records.reduce((acc, record) => acc + record.duration, 0);
                            return (
                                <li key={bucketTitle}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="body2">
                                            <b>{bucketTitle}:</b> {convertNumToTimeString(totalDuration, t)}
                                        </Typography>
                                        <IconButton onClick={() => toggleExpand(bucketTitle)} sx={{p: 0.5, ml: 1}}>
                                            {expandedBuckets[bucketTitle] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box>
                                    {expandedBuckets[bucketTitle] && (
                                        <ul style={{padding: '0 0 8px 16px', margin: 0}}>
                                            {records.map((record: RequesterCaseHasTimeBucketTypes) => (
                                                <TimeRecordItem item={record} key={`watcher-${record.uuid}`} />
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                </ul>
            </Box>
            {isEditEnabled && <AddTimeRecordButton />}
        </Box>
    );
};

export default TimeTracking;
