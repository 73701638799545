import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import SectionPrintContent from 'pages/agent/print/partials/SectionPrintContent';

import {getPageKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface PagePrintContentType {
    pageNumber: number;
    page: FormPageTypes;
    expandAll: boolean;
    result: RequesterCaseItemType;
    resultAgent: RequesterCaseItemType;
}

const PagePrintContent: FC<PagePrintContentType> = ({page, pageNumber, expandAll, result, resultAgent}) => {
    const [t] = useTranslation();
    const {formId} = useParams();

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {title, id, isAgentPage, versionId, sections} = page;

    const keyword = getPageKeyword(Number(formId), id, versionId, 'title');

    return (
        <Box sx={{mt: 2}}>
            <Typography align="left" mb={2} sx={{fontSize: 24, fontWeight: 300}}>
                {`${t('orguser.forms.page')}.${pageNumber} ${getFormTranslatedLabel(translations, keyword, title)}`}
            </Typography>
            {sections &&
                sections.map((item: FormSectionTypes, index: number) => {
                    return (
                        <SectionPrintContent
                            key={`section-${index}`}
                            pageId={id}
                            section={item}
                            expandAll={expandAll}
                            result={isAgentPage ? resultAgent : result}
                        />
                    );
                })}
        </Box>
    );
};

export default PagePrintContent;
