import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RequesterCaseHasTimeBucketTypes} from 'appRedux/actions/requestCase/types';

import EmptyArea from 'components/EmptyArea';

import DeleteTimeRecordButton from 'pages/agent/requesterPage/timeTracking/DeleteTimeRecordButton';
import EditTimeRecordButton from 'pages/agent/requesterPage/timeTracking/EditTimeRecordButton';

import {convertNumToTimeString} from 'helpers/parseTimeString';

interface TimeRecordItemType {
    item: RequesterCaseHasTimeBucketTypes;
}

const TimeRecordItem: FC<TimeRecordItemType> = ({item}) => {
    const [t] = useTranslation();

    const {duration, text} = item;

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <li>
                    <Typography variant="body2">
                        <b>{t('orguser.requesterCase.timeTracking.time')}:</b> {convertNumToTimeString(duration, t)}
                    </Typography>
                </li>
                <EmptyArea />
                <EditTimeRecordButton item={item} />
                <DeleteTimeRecordButton item={item} />
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {text ? (
                    <Typography variant="body2">
                        <b>{t('orguser.requesterCase.timeTracking.description')}:</b>{' '}
                        <span dangerouslySetInnerHTML={{__html: text.replace(/\n/g, '<br/>')}}></span>
                    </Typography>
                ) : (
                    <Typography variant="body2">{t('common.none')}</Typography>
                )}
            </Box>
        </>
    );
};

export default TimeRecordItem;
