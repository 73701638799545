import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';
import {FormTimeBucketTypes} from 'appRedux/actions/forms/types';

import {CASE_STATUS_CHANGE_DEFAULT} from 'components/Forms/RequesterCase/helper';

import RequesterNameBlock from 'pages/agent/requesterPage/partials/RequesterNameBlock';
import RequesterInfoFormBuilder from 'pages/agent/requesterPage/RequesterInfoFormBuilder';
import FormInformation from 'pages/agent/requesterPage/formInformation/FormInformation';
import TagsList from 'pages/agent/requesterPage/tags/TagsList';
import RightPanelSectionWrapper from 'pages/agent/requesterPage/wrappers/RightPanelSectionWrapper';
import AttachedFiles from 'pages/agent/requesterPage/partials/AttachedFiles';
import OverallComments from 'pages/agent/requesterPage/overallComments/OverallComments';
import BottomButtonsPanel from 'pages/agent/requesterPage/partials/BottomButtonsPanel';
import Messenger from 'pages/agent/requesterPage/messenger/Messenger';
import ApproverResolution from 'pages/agent/requesterPage/approverResolution/ApproverResolution';
import AdditionalForms from 'pages/agent/requesterPage/additionalForms/AdditionalForms';
import TimeTracking from 'pages/agent/requesterPage/timeTracking/TimeTracking';

interface RequesterPageContentType {
    isChatOpened: boolean;
    setIsChatOpened: (value: boolean) => void;
    toggleIsChatOpened: () => void;
}

const RequesterPageContent: FC<RequesterPageContentType> = ({isChatOpened, setIsChatOpened, toggleIsChatOpened}) => {
    const [t] = useTranslation();

    const {
        admin: {formInfo},
        profile: {profile},
        requestCase: {
            currentCase: {
                comments,
                invites,
                subFormRequests,
                approves,
                totalFilesNumber,
                isAnonymousAccount,
                hasStatusChangingPermission,
            },
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isAlreadyApprovedByCurrentUser = approves.some(
        item => item.status !== CASE_STATUS_CHANGE_DEFAULT && profile && item.approverId === profile.id,
    );

    const additionalForms = get(formInfo, 'additionalForms', []);
    const overallCommentsNumber = comments ? comments.filter(item => item.section === null) : [];

    const hasActiveAdditionalForms = additionalForms.some(item => !item.isSubForm);
    const hasActiveSubForms = additionalForms.some(item => item.isSubForm);

    const showAdditionalFormsBlock =
        !isAnonymousAccount &&
        (hasActiveAdditionalForms ||
            hasActiveSubForms ||
            (invites && invites.length > 0) ||
            (subFormRequests && subFormRequests.length > 0));

    const formTimeBuckets: FormTimeBucketTypes[] = get(formInfo, 'timeBuckets', []);

    return (
        <Box>
            <Grid container>
                <Grid item sm={12} md={8} sx={{pb: 1}}>
                    <RequesterNameBlock toggleIsChatOpened={() => setIsChatOpened(true)} />
                </Grid>
                <Grid sm={12} md={4} item sx={{pt: 2}}>
                    <BottomButtonsPanel />
                </Grid>
                <Grid sm={12} md={8} item sx={{pr: 2}}>
                    <RequesterInfoFormBuilder toggleIsChatOpened={() => setIsChatOpened(true)} />
                </Grid>
                <Grid sm={12} md={4} sx={{pb: 10}} item>
                    <FormInformation closeChat={() => setIsChatOpened(false)} />
                    <TagsList />
                    {hasStatusChangingPermission && !isAlreadyApprovedByCurrentUser && <ApproverResolution />}
                    {formTimeBuckets.length > 0 && (
                        <RightPanelSectionWrapper title={t('orguser.requesterCase.timeTracking.timeTracking')}>
                            <TimeTracking />
                        </RightPanelSectionWrapper>
                    )}
                    <RightPanelSectionWrapper
                        title={t('orguser.requesterCase.comments.overallComments')}
                        subtitle={overallCommentsNumber.length}
                    >
                        <OverallComments comments={comments} />
                    </RightPanelSectionWrapper>
                    {showAdditionalFormsBlock && (
                        <RightPanelSectionWrapper
                            title={t('orguser.requesterCase.additionalForms.additionalForms')}
                            subtitle={invites.length + subFormRequests.length}
                        >
                            <AdditionalForms
                                invites={invites}
                                subFormRequests={subFormRequests}
                                hasActiveAdditionalForms={hasActiveAdditionalForms}
                                hasActiveSubForms={hasActiveSubForms}
                            />
                        </RightPanelSectionWrapper>
                    )}
                    {totalFilesNumber > 0 && (
                        <RightPanelSectionWrapper
                            title={t('orguser.requesterCase.documents.attachments')}
                            subtitle={totalFilesNumber}
                        >
                            <AttachedFiles />
                        </RightPanelSectionWrapper>
                    )}
                </Grid>
            </Grid>
            {isChatOpened && <Messenger toggleIsOpened={toggleIsChatOpened} />}
        </Box>
    );
};

export default RequesterPageContent;
