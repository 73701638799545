import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {REQUEST_CASE_ASSIGN_AGENT, REQUEST_CASE_UNASSIGNED_AGENT} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

interface ChangeAgentFormType {
    initialAgent?: number;
    closeChat: () => void;
}

const ChangeAgentForm: FC<ChangeAgentFormType> = ({initialAgent, closeChat}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {isLargeDesktop} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);

    const {
        crypto: {agentsWithCaseAccess},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [assignedAgent, setAssignedAgent] = useState<number | undefined>();

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const assignAgent = useCallback(
        data => dispatch({type: REQUEST_CASE_ASSIGN_AGENT.REQUEST, payload: data}),
        [dispatch],
    );

    const unassignAgent = useCallback(
        data => dispatch({type: REQUEST_CASE_UNASSIGNED_AGENT.REQUEST, payload: data}),
        [dispatch],
    );

    const assignAgentRequest = (agentId: string | number) => {
        assignAgent({
            agentId,
            caseId: Number(caseId),
            showAlert,
            callback: () => {
                closeChat();
                setAssignedAgent(agentId as unknown as number);
                getFormByCaseInformation({id: caseId});
            },
        });
    };

    const handleAgentChange = (event: SelectChangeEvent) => {
        const agentId = event.target.value;
        if (parseInt(agentId) !== assignedAgent) {
            assignAgentRequest(agentId);
        }
    };

    const handleAssignToMe = () => {
        if (profile && profile.id !== assignedAgent) {
            assignAgentRequest(profile.id);
        }
    };

    const handleUnassignAgent = () => {
        unassignAgent({
            caseId: Number(caseId),
            showAlert,
            callback: () => {
                closeChat();
                setAssignedAgent(undefined);
            },
        });
    };

    useEffect(() => {
        setAssignedAgent(initialAgent != undefined ? initialAgent : 0);
    }, [initialAgent]);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isLargeDesktop ? 'row' : 'column',
                    alignItems: isLargeDesktop ? 'center' : 'start',
                }}
            >
                <FormControl fullWidth={!isLargeDesktop || !!initialAgent}>
                    <Select
                        fullWidth
                        name="agent"
                        value={String(assignedAgent)}
                        variant="standard"
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={handleAgentChange}
                        disableUnderline
                        sx={{
                            '& div': {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            },
                        }}
                    >
                        {!initialAgent && (
                            <MenuItem key={'no-agent'} value={0}>
                                <Typography sx={{fontWeight: 600}}>{t('orguser.requesterCase.notAssigned')}</Typography>
                            </MenuItem>
                        )}
                        {agentsWithCaseAccess &&
                            agentsWithCaseAccess.map(agent => {
                                const {id, avatarType, avatarLink, firstName, lastName, email} = agent;
                                const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                return (
                                    <MenuItem
                                        key={email}
                                        value={id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            mb: 1,
                                        }}
                                    >
                                        <AvatarImage
                                            size={25}
                                            avatarType={avatarType}
                                            avatarLink={avatarLink}
                                            avatarTitle={avatarTitle}
                                        />
                                        <Typography sx={{ml: 1, fontWeight: 600}}>
                                            {firstName && lastName ? `${firstName} ${lastName}` : email}
                                        </Typography>
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                {!initialAgent && agentsWithCaseAccess.length > 0 && (
                    <Typography
                        variant="body2"
                        sx={{ml: 0.5, cursor: 'pointer', textDecoration: 'underline'}}
                        onClick={handleAssignToMe}
                    >
                        {t('orguser.requesterCase.assignToMe')}
                    </Typography>
                )}
            </Box>
            {initialAgent && (
                <Typography
                    variant="body2"
                    sx={{mt: 1, cursor: 'pointer', textDecoration: 'underline'}}
                    onClick={handleUnassignAgent}
                >
                    {t('orguser.requesterCase.unassignAgent')}
                </Typography>
            )}
        </Box>
    );
};

export default ChangeAgentForm;
