import React, {FC, useCallback, useContext, useEffect} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';

import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import LockSvgIcon from 'assets/icons/buttons/LockSvgIcon';
import NoEditSvgIcon from 'assets/icons/buttons/NoEditSvgIcon';
import SendMailSvgIcon from 'assets/icons/buttons/SendMailSvgIcon';
import AddFileSvgIcon from 'assets/icons/buttons/AddFileSvgIcon';
import NotificationSvgIcon from 'assets/icons/buttons/NotificationSvgIcon';
import AnonymousSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';
import ListSvgIcon from 'assets/icons/buttons/ListSvgIcon';
import WordPressSvgIcon from 'assets/icons/buttons/WordPressSvgIcon';

import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {ORGANIZATION_PDF_TEMPLATES_LIST, ORGANIZATION_PRIVACY_POLICY_LIST} from 'appRedux/actions/organization';
import {OrganizationPdfTemplatesTypes, OrganizationPrivacyPolicyItemTypes} from 'appRedux/actions/organization/types';

import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import {makeAbbreviation} from 'components/Forms/FormBuilder/ConfigurationForm/helper';
import {formValidationSchema} from 'components/Forms/FormBuilder/ConfigurationForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import CheckboxIconButton from 'components/AdminScreenComponents/CheckboxIconButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import UpdateFormWorkflowButton from 'components/Forms/FormBuilder/ConfigurationForm/UpdateFormWorkflowButton';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';

import {getLanguageById} from 'helpers/translationsHelper';

interface ConfigurationFormType {
    initialValues: FormConfigurationRequestTypes;
    onSubmitClicked: (values: FormConfigurationRequestTypes) => void;
    isLoading: boolean;
    isCreate?: boolean;
}

const ConfigurationForm: FC<ConfigurationFormType> = ({initialValues, isLoading, isCreate, onSubmitClicked}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationPrivacyPolicies = useCallback(
        data => dispatch({type: ORGANIZATION_PRIVACY_POLICY_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationPdfTemplatesList = useCallback(
        data => dispatch({type: ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationPrivacyPolicies, languageList, organizationPdfTemplates},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {isActive, isLocked} = initialValues;

    useEffect(() => {
        if (myOrganization) {
            const organizationLanguage = getLanguageById(languageList, myOrganization.language);
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
            getOrganizationPrivacyPolicies({
                id: myOrganization.id,
                language: organizationLanguage.name,
                showAlert,
            });
            getOrganizationPdfTemplatesList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization, isCreate]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => formValidationSchema(t)}
            enableReinitialize
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    policy: values.policy === 0 ? null : values.policy,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<FormConfigurationRequestTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.forms.title')}
                            component={FormikTextInput}
                            onFieldValueChange={(e: React.ChangeEvent<any>) => {
                                if (!formik.touched.handlePrefix) {
                                    console.log(makeAbbreviation(e.target.value));
                                    formik.setFieldValue('handlePrefix', makeAbbreviation(e.target.value));
                                }
                            }}
                        />
                        {isLocked && (
                            <Alert severity={'error'} sx={{mt: 2, mb: 1}}>
                                <Typography>{t('orguser.forms.formIsLocked')}</Typography>
                            </Alert>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Field
                                as="select"
                                required
                                name={'workflow'}
                                label={t('orguser.forms.workflow')}
                                options
                                disabled={!isCreate}
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                            {!isCreate && (
                                <UpdateFormWorkflowButton
                                    currentWorkflowId={Number(initialValues.workflow)}
                                    isLocked={isLocked}
                                />
                            )}
                        </Box>
                        <Box sx={{mt: 1}}>
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.forms.description')}
                            />
                        </Box>
                        <Field
                            name="url"
                            disabled={isActive}
                            placeholder={t('orguser.forms.formUrl')}
                            component={FormikTextInput}
                            prefix={`https://${myOrganization?.domainName}/`}
                        />
                        <Field
                            name="handlePrefix"
                            placeholder={t('orguser.forms.handlePrefix')}
                            component={FormikTextInput}
                        />

                        <Field
                            name="formButtonText"
                            placeholder={t('orguser.forms.requestHelp')}
                            component={FormikTextInput}
                        />
                        <Field
                            as="select"
                            required
                            name={'templatePdf'}
                            label={t('orguser.forms.pdfTemplate')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {organizationPdfTemplates &&
                                organizationPdfTemplates.map((template: OrganizationPdfTemplatesTypes) => {
                                    const {id, uuid, title} = template;
                                    return (
                                        <MenuItem key={`pdf-template-${uuid}`} value={id}>
                                            <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                        <Field
                            as="select"
                            required
                            name={'policy'}
                            label={t('orguser.forms.policyTemplate')}
                            options
                            component={FormikSelectInput}
                            fullwidth
                        >
                            {organizationPrivacyPolicies &&
                                organizationPrivacyPolicies.map((item: OrganizationPrivacyPolicyItemTypes) => {
                                    const {id, uuid, title, versions} = item;
                                    if (versions.length > 0) {
                                        return (
                                            <MenuItem key={`privacy-policy-${uuid}`} value={id}>
                                                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                            </MenuItem>
                                        );
                                    }
                                    return null;
                                })}
                            <MenuItem key={`privacy-policy-custom`} value={0}>
                                <Typography sx={{fontWeight: 600}}>
                                    {t('orguser.forms.policyTemplateCustom')}
                                </Typography>
                            </MenuItem>
                        </Field>
                        {!formik.values.policy && (
                            <MdxEditorField
                                name="privacyPolice"
                                value={formik.values.privacyPolice ? String(formik.values.privacyPolice) : ''}
                                label={t('orguser.forms.privacyPolicy')}
                            />
                        )}
                        <Box sx={{mt: 2, mb: 2}}>
                            <MdxEditorField
                                name="submitMessage"
                                value={formik.values.submitMessage ? String(formik.values.submitMessage) : ''}
                                label={t('orguser.forms.submitMessage')}
                            />
                        </Box>
                        <Box>
                            <MdxEditorSmallField
                                name="pauseReason"
                                value={formik.values.pauseReason ? String(formik.values.pauseReason) : ''}
                                label={t('orguser.forms.pauseReason')}
                            />
                        </Box>
                        <Box sx={{mt: 2, display: 'flex', gap: 2}}>
                            <Field
                                type="checkbox"
                                name="isChatActivated"
                                label={t('orguser.forms.activateChatOnDefault')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={MessagesSvgIcon}
                                        title={t('orguser.forms.defaultChat')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isEmailsEnabled"
                                label={t('orguser.forms.isEmailEnabled')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NotificationSvgIcon}
                                        title={t('orguser.forms.email')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isAutoChatEnabled"
                                label={t('orguser.forms.isChatMailingEnabled')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NotificationSvgIcon}
                                        title={t('orguser.forms.chat')}
                                    />
                                )}
                            />
                            {myOrganization && myOrganization.isEncryptionPossible && (
                                <Field
                                    type="checkbox"
                                    name="isEncryptInfo"
                                    label={t('orguser.forms.isEncryptInfo')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={LockSvgIcon}
                                            title={t('orguser.forms.encryption')}
                                        />
                                    )}
                                />
                            )}
                            {myOrganization && myOrganization.isMultipleRequestPossible && (
                                <Field
                                    type="checkbox"
                                    name="isMultipleRequestPossible"
                                    label={t('orguser.forms.multipleRequestPossible')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={AddFileSvgIcon}
                                            title={t('orguser.forms.multipleRequests')}
                                        />
                                    )}
                                />
                            )}
                            {myOrganization && myOrganization.isInviteRegistration && (
                                <Field
                                    type="checkbox"
                                    name="isOnlyInvite"
                                    label={t('orguser.forms.registrationByInvite')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={SendMailSvgIcon}
                                            title={t('orguser.forms.inviteOnly')}
                                        />
                                    )}
                                />
                            )}
                            {myOrganization &&
                                myOrganization.isShowFormsList &&
                                !formik.values.isOnlyInvite &&
                                !formik.values.isIntegrationForm &&
                                !formik.values.isAnonymousRequestPossible && (
                                    <Field
                                        type="checkbox"
                                        name="isShowInList"
                                        label={t('orguser.forms.isShowInList')}
                                        component={props => (
                                            <CheckboxIconButton
                                                {...props}
                                                Icon={ListSvgIcon}
                                                title={t('orguser.forms.isShowInListShort')}
                                            />
                                        )}
                                    />
                                )}
                            <Field
                                type="checkbox"
                                name="noEditForm"
                                label={t('orguser.forms.requestNotEditableAfterSubmit')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        Icon={NoEditSvgIcon}
                                        title={t('orguser.forms.notEditable')}
                                    />
                                )}
                            />
                            <Field
                                type="checkbox"
                                name="isAnonymousRequestPossible"
                                label={t('orguser.forms.isAnonymousRequestPossible')}
                                component={props => (
                                    <CheckboxIconButton
                                        {...props}
                                        disabled={!isCreate}
                                        Icon={AnonymousSvgIcon}
                                        title={t('orguser.forms.anonymous')}
                                    />
                                )}
                            />
                            {myOrganization && myOrganization.isIntegrationFormEnabled && (
                                <Field
                                    type="checkbox"
                                    name="isIntegrationForm"
                                    label={t('orguser.forms.isIntegrationForm')}
                                    component={props => (
                                        <CheckboxIconButton
                                            {...props}
                                            Icon={WordPressSvgIcon}
                                            title={t('orguser.forms.isIntegrationForm')}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isLoading={isLoading}
                                title={t(isCreate ? 'orguser.forms.addNewForm' : 'common.buttons.saveButton')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ConfigurationForm;
