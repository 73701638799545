import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';

import {getSelectedOptionsList} from 'pages/agent/requesterPage/sections/helper';
import {getOptionValue} from 'pages/agent/activityPage/helper';

interface FieldPrintContentType {
    formId: number;
    field: FormFieldTypes;
    clientText: string;
}

const SelectedFieldPrintContent: FC<FieldPrintContentType> = ({formId, field, clientText}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {options},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const selectedOptions = field.fieldOptions ? getSelectedOptionsList(field.fieldOptions, options) : [];

    return (
        <Typography sx={{fontSize: isMobile ? 12 : 14, mt: 0.5}}>
            {selectedOptions.length > 0 && field.fieldOptions
                ? getOptionValue(formId, field.type, field.fieldOptions, clientText, translations)
                : t('common.none')}
        </Typography>
    );
};

export default SelectedFieldPrintContent;
